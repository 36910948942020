import React from 'react';
import Icons from './ui/Icons';
import Icon from '../com/efile/ui/Icon';
import StringUtil from './util/StringUtil';

export default class SiteSearch extends React.Component {

    constructor() {
        super();

        this.state = { searchText : "" };

        Icons;
    }

    _onSubmit =() => {
      if (this.state.searchText) {
        window.open("/oferta/szukaj?q=" + encodeURIComponent(this.state.searchText), "_self");
      }
    };

    _onChangeInput = (event) => {
      let s = event.target.value;
      s = StringUtil.trim(s);
        this.setState({ searchText : s } );
    };

    _onKeyDown = (event) => {
        if (event.keyCode === 13) {
          this._onSubmit();
        }
    };

    render() {
        return <div className="SiteSearch">
                  <input type="text" value={this.state.searchText} onKeyDown={this._onKeyDown} onChange={this._onChangeInput} placeholder="CZEGO SZUKASZ?" />
                    {this.state.searchText ? <button className="red" onClick={this._onSubmit}>
                      <Icon embedSVG size={24} icon={Icons.SEARCH} title="Szukaj" />
                    </button> : null}
                </div>
    }
}
