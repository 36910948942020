import ObjectUtil from "../epity/mobile/util/ObjectUtil";

export default class UIComponentUtil {

    static checkShouldUpdate(component, props, nextProps, state, nextState, log = false) {
        //console.log("UIComponentUtil.checkShouldUpdate", component.name);

        let changedProps = !ObjectUtil.compare(nextProps, props);
        let changedState = !ObjectUtil.compare(nextState, state);

        if (changedProps) {
            changedProps = !ObjectUtil.compare(nextProps, props, true); //Sprawdzamy jeszcze z zagnieżdzonymi obiektami

            if (log && changedProps) {
                let changedPropsList = ObjectUtil.shallowEqualChanges(props, nextProps);
                for (let prop of changedPropsList) {
                    console.log(component.name, "changedProp [" + prop + "]", JSON.stringify(props[prop]), ">", JSON.stringify(nextProps[prop]));
                }
            }
        }

        if (changedState) {
            changedState = !ObjectUtil.compare(nextState, state, true); //Sprawdzamy jeszcze z zagnieżdzonymi obiektami

            if (log && changedState) {
                let changedStateList = ObjectUtil.shallowEqualChanges(state, nextState);
                for (let prop of changedStateList) {
                    console.log(component.name, "changedState [" + prop + "]", JSON.stringify(state[prop]), ">", JSON.stringify(nextState[prop]));
                }
            }
        }

        if (changedProps || changedState) {
            if (log) {
                console.log(component.name, "should", changedProps, changedState);
            }
            return true;
        }
        return false;
    }
}
