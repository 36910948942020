import StringUtil from "./StringUtil";

export default class ValidationUtil {

    static isValidPositiveNumber(n) {
        return n != null && n != undefined && !isNaN(n) && parseFloat(n) > 0;
    }

    static isValidString(n) {
        return typeof(n) === "string" && n != null && n != "null" && n != "";
    }

    static isValidEmail(s) {
        return ValidationUtil.validateEmail(s).length == 0;
    }

    static isText(s) {
        return s != undefined && s != null && s != "" && s != "null";
    }

    static isValue(s) {
        return s != undefined && s != null && s != "" && String(s) != "NaN" && s != "null";
    }

    static isXML(s) {
        if (!s) {
            return false;
        }

        var oParser = new DOMParser();
        var oDOM = oParser.parseFromString(s, "text/xml");
        var valid = oDOM.documentElement.nodeName != "parsererror" && oDOM.documentElement.nodeName != "html" && (!oDOM.documentElement.innerHTML || oDOM.documentElement.innerHTML.indexOf("parsererror") == -1);
        if (!valid) {
            console.error("ValidationUtil.isXML", oDOM.documentElement.textContent);
        }
        return valid;
    }

    /**
     * Hasło używane np. do chmury e-file. Hasło musi zawierać minimum 8 znaków, małe i wielkie litery, cyfry i minimum jeden znak specjalny.
     *
     * @param password
     * @returns {boolean}
     */
    static isValidStrongPassword(password) {
        let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-=_+\[\]\{\};\':"\\\,\.<>\/?])[A-Za-z\d!@#$%^&*()-=_+\[\]\{\};\':"\\\,\.<>\/?]{8,}$/;
        return regex.test(password);
    }

    /**
     * https://www.npmjs.com/package/email-validator
     *
     * @param s
     */
    static validateEmail(s) {
        var validator = require("email-validator");
        if (!validator.validate(s)) {
            return ["Adres e-mail jest nieprawidłowy"];
        }
        return [];
    }

    static validatePESEL(pesel) {
        if (!pesel || pesel == "" || pesel == undefined) {
            return ["Numer PESEL jest pusty"];
        }
        pesel = String(pesel);
        if (pesel == "00000000000"){
            return ["PESEL nie może mieć samych zer - dla nierezydenta wpisz NIP 9999999999"];
        }
        if (pesel.length < 11) {
            return ["Numer PESEL jest zbyt krótki"];
        }
        var wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        var suma = 0;
        for (var i = 0; i < pesel.length - 1; i++) {
            suma += safeParseInt(pesel.charAt(i)) * wagi[i];
        }

        suma = 10 - (suma % 10);
        suma = suma >= 10 ? suma % 10 : suma;

        if (safeParseInt(pesel.charAt(10)) != suma) {
            return ["Numer PESEL jest nieprawidłowy"];
        }

        //JG: Dodatkowo sprawdzamy jeszcze czy data z PESEL jest poprawna (https://app.asana.com/0/766281783386960/1119960319705434)
        var peselDate = DataFormatter.dateFromPESEL(pesel);
        if (!peselDate || (peselDate.getTime() > new Date().getTime())) {
            return ["Numer PESEL jest nieprawidłowy"];
        }

        return [];
    }

    static isValidPESEL(pesel) {
        return ValidationUtil.validatePESEL(pesel).length == 0;
    }
    
    static validateNIPPESEL(v) {
        let errorsNIP = ValidationUtil.validateNIP(v);
        let errorsPESEL = ValidationUtil.validatePESEL(v);
        return errorsNIP.length > 0 && errorsPESEL.length > 0 ? ["Podaj poprawny numer NIP lub PESEL"] : [];
    }

    static validateNIP(nip) {
        if (!nip || nip == "" || nip == undefined) {
            return ["Numer NIP jest pusty"];
        }
        if (nip == "0000000000") {
            return ["NIP nie może mieć samych zer - dla nierezydenta wpisz NIP 9999999999"];
        }
        nip = String(nip);
        nip = nip.replace(/\-/g, ''); //wycina '-'

        var beg = nip.substr(0, 2);

        if (ValidationUtil.VAT_UE_CODES.indexOf(beg) > -1) {
            return [];//jeśli 2 pierwsze znaki mamy na liście przedrostków, to nie walidujemy dalej
        }
        else if (beg == "PL") {
            nip = nip.substr(2);//pomijam 2 pierwsze znaki i sprawdzam dalej normalnie
        }
        if (nip.length == 10 && !isNaN(Number(nip))) {
            var mnoznik = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            var nSum = 0;
            for (var i = 0; i < 9; i++) {
                nSum = nSum + safeParseInt(nip.charAt(i)) * mnoznik[i];
            }
            if (nSum % 11 == safeParseInt(nip.charAt(9))) {
                return [];
            }
        }
        return ["Numer NIP jest nieprawidłowy"];
    }

    static isValidNIP(nip) {
        return ValidationUtil.validateNIP(nip).length == 0;
    }

    static validateREGON(regon) {
        if (!regon || regon == "" || regon == undefined) {
            return ["Numer REGON jest pusty"];
        }
        regon = String(regon);
        if (regon.length != 9 && regon.length != 14) {
            return ["Numer REGON powinien mieć 9 lub 14 znaków"];
        }

        //obcinamy jeśli ma same zera na końcu (FieldFormats.FORMAT_WYPELNIJ_ZERAMI)
        if (regon.length == 14) {
            var zeros = true;
            for (var j = 9; j < 14; j++) {
                if (regon.charAt(j) != "0") {
                    zeros = false;
                }
            }
            if (zeros) {
                regon = regon.substr(0,9);
            }
        }

        var suma = 0;
        var i;
        if (regon.length == 9) {
            var mnoznik1 = [8, 9, 2, 3, 4, 5, 6, 7];
            for (i = 0; i < 8; i++) {
                suma += safeParseInt(regon.charAt(i)) * mnoznik1[i];
            }
            suma = suma % 11;
        }
        else if (regon.length == 14) {
            var mnoznik2 = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
            for (i = 0; i < 13; i++) {
                suma += safeParseInt(regon.charAt(i)) * mnoznik2[i];
            }
            suma = suma % 11;
        }

        if (suma == 10) {
            suma = 0;
        }

        if (safeParseInt(regon.charAt(regon.length - 1)) != suma) {
            return ["Numer REGON jest nieprawidłowy"];
        }

        return [];
    }

    static isValidREGON(REGON) {
        return ValidationUtil.validateREGON(REGON).length == 0;
    }

    static validatePostCode(kod) {
        if (!kod || kod == "" || kod == undefined) {
            return ["Kod pocztowy jest pusty"];
        }
        kod = String(kod);

        if (!kod.match(/^\d{2}-\d{3}$/)) { //jeśli kod jest pusty to nie pasuje
            return ["Kod pocztowy jest nieprawidłowy"]; //jeśli nie pasuje do wzorca i jest wymagany to błąd
        }

        return [];
    }

    static isValidKRS(v) {
        return ValidationUtil.validateKRS(v).length == 0;
    }

    static validateKRS(v, fieldDef) {
        if (!v || v == "" || v == undefined || String(v).length == 0) {
            return [];
        }
        v = String(v);
        if (v.length != 10) {
            return ["Numer KRS powinien mieć 10 znaków"];
        }

        if (v == '0000000000' || v.search(/[a-zA-Z]/) > -1) {
            return "Numer KRS jest nieprawidłowy";
        }

        return [];
    }
    
    static validateMaxChars(v, fieldDef) {
        if (!v || v == "") {
            return [];
        }

        if (fieldDef && fieldDef.maxChars && v.length > fieldDef.maxChars) {
            return ["Proszę podać maksymalnie " + fieldDef.maxChars + " znaków"];
        }
        return [];
    }

    static validateMonth(v, fieldDef) {
        let validErrs = ValidationUtil.validateMaxChars(v, fieldDef);
        if (validErrs.length != 0){
            return validErrs;
        }
        else{
            if (v && v != ""){
                let month = safeParseInt(v);
                if (month<1 || month >12){
                    return ["Nieprawidłowy miesiąc"];
                }
            }
        }

        return [];
    }


    static isValidPostCode(kod) {
        return ValidationUtil.validatePostCode(kod).length == 0;
    }

    /**
     * Sprawdzanie daty w formacie DD-MM-YYYY
     * @param date
     * @returns {*}
     */
    static validateDate(date, fieldDef, birthDate = false) {
        let parsedDate;
        if (date instanceof Date) {
            parsedDate = date;
        }
        else {
            if (!date || date == undefined) {
                return ["Nie podano daty"];
            }

            date = StringUtil.trim(date);

            let format = DataFormatter.DEFAULT_DATE_FORMAT;
            if (fieldDef && fieldDef.format) {
                format = DataFormatter.dateFormat(fieldDef.format);
            }

            parsedDate = DataFormatter.parseDate(date, format);
            if (!parsedDate) {
                return ["Data jest niepoprawna. Podaj datę w formacie " + format];
            }
        }

        if (birthDate && ((parsedDate.getFullYear() > Config.AKTUALNY_ROK + 1) || (parsedDate.getFullYear() <= 1900 ))) {
            return ["Podaj poprawną datę urodzenia"];
        }

        return [];
    }

    //TODO AW powtorzony kod - skorzystac z funkcji powyzej - nie musza komunikaty być idealnie
    static validateBirthDate(date, fieldDef) {
        return ValidationUtil.validateDate(date, fieldDef, true);
    }

    static isValidDate(date) {
        return ValidationUtil.validateDate(date).length == 0;
    }

    static validateIBAN(iban) {
        if (!iban || iban == "") {
            return ["Numer rachunku jest pusty"];
        }

        var tempStr = iban;
        //wywalić spacje:
        while (tempStr.indexOf(" ") > -1)
            tempStr = tempStr.replace(" ", "");

        //AM jeśli z przodu liczba (nie podano kraju) to dopisz standardowo PL
        if (tempStr.substr(0, 1).match("[0-9]+")) {
            tempStr = "PL" + tempStr;
        }
        if (tempStr.match("[a-z]+")) {
            return ["Wpisując numer IBAN, kod kraju należy podać wielkimi literami"];
        }

        var nums = {
            A: 10, B: 11, C: 12, D: 13, E: 14, F: 15, G: 16, H: 17, I: 18, J: 19, K: 20, L: 21, M: 22,
            N: 23, O: 24, P: 25, Q: 26, R: 27, S: 28, T: 29, U: 30, V: 31, W: 32, X: 33, Y: 34, Z: 35
        };

        var chars = tempStr.split('');

        for (var i = 0; i < 4; i++) {
            chars.push(chars.shift());
        }

        var exp = /[a-z]/i;
        for (var j = 0; j < chars.length; j++) {
            chars[j] = exp.test(chars[j]) ? nums[chars[j].toUpperCase()] : chars[j];
        }
        tempStr = chars.join('');
        var valid = ValidationUtil.modulus(tempStr, 97) == 1;
        return valid ? [] : ["Numer rachunku jest niepoprawny"];
    }

    static modulus(largeNumber, mod) {
        var tmp = largeNumber.substr(0, 10);
        var number = largeNumber.substr(tmp.length);
        var result;

        do {
            result = String(Number(tmp) % mod);
            number = result + number;
            tmp = number.substr(0, 10);
            number = number.substr(tmp.length);

        } while (number.length > 0);

        return Number(tmp) % mod;
    }

    static isValidIBAN(iban) {
        return ValidationUtil.validateIBAN(iban).length == 0;
    }

    static validateSWIFT(value) {
        if (!value || value == "") {
            return ["Numer SWIFT jest pusty"];
        }

        var pattern = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
        var valid = pattern.test(String(value));
        return valid ? [] : ["Numer SWIFT jest niepoprawny"];
    }

    static isValidSWIFT(value) {
        return ValidationUtil.validateSWIFT(value).length == 0;
    }

    /**
     * Check if a given string is a valid polish zip code.
     *
     * @param    str        String to check.
     * @return            True if valid, false otherwise.
     */
    /*static isPolishZipCode(str) {
     var rx:RegExp = /^\d{2}-\d{3}$/;
     return rx.test(str);
     }*/

    static checkNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        //console.log("charCode", charCode);
        //znaki nieliczbowe
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
    }

    static checkNumberAndDashKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        //console.log("charCode", charCode);
        if (charCode == "-".charCodeAt(0)) {
            return;
        }
        //znaki nieliczbowe
        if (charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
    }

    static checkDateKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        //console.log("charCode", charCode);
        if (charCode == "-".charCodeAt(0) || charCode == ".".charCodeAt(0) || charCode == "/".charCodeAt(0)) {
            return;
        }
        //znaki nieliczbowe
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
    }

    static isKrajPolska(value) {
        if (!ValidationUtil.isText(value)
            || String(value).toLowerCase() == 'polska'
            || String(value).toLowerCase() == 'pl'
            || String(value).toLowerCase() == 'polska (pl)') {
            return true;
        }
        return false;
    }

}

ValidationUtil.VAT_UE_CODES = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "EL", "ES", "FI", "FR", "GB", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PT", "RO", "SE", "SI", "SK"];

window.ValidationUtil = ValidationUtil;