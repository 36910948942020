import React from "react";
import UIComponent from "./UIComponent";
import StringUtil from "../epity/mobile/util/StringUtil";
import { ReactSVG } from 'react-svg'

export default class Icon extends UIComponent {

    constructor(props){
        super(props);
        this.customPropsList.push("size", "icon", "spin", "rotate", "width", "height", "iconStyle", "embedSVG"); //TODO Obsluga spin/rotate
    }

    render() {
        if (!this.state.visible || !this.props.visible) {
            return null;
        }

        if (!this.props.icon) {
            console.error("Icon", "invalid this.props.icon");
            return null;
        }

        let icon = this.props.icon;
        if (typeof(icon) !== "string") {
            console.error("Icon", "invalid this.props.icon - not string");
            return null;
        }

        let props = this._getValidProps();
        props['className'] = this.getClassName();

        //JG: Dodajemy do nazwy klasy przekonwertowaną ścieżkę do ikonki - dodatkowe css dla nowych ikonek fillUp
        if (icon.indexOf("/") > -1) {
            let sourceClassName = String(icon).toLowerCase();
            sourceClassName = StringUtil.replace(sourceClassName, ".", "_");
            sourceClassName = StringUtil.replace(sourceClassName, " ", "");
            sourceClassName = StringUtil.replace(sourceClassName, "-", "");
            let splitted = sourceClassName.split("/");
            let fileName = splitted.pop();
            sourceClassName = splitted.join("_");
            props['className'] += " " + sourceClassName + " " + fileName;
        }

        if (this.props.spin) {
            props['className'] += " spin";
        }

        const s = this.props.size;
        let w = this.props.width;
        let h = this.props.height;
        if (!w) {
            w = s;
        }
        if (!h) {
            h = s;
        }
        props['style'] = Object.assign({}, this.props.style, { minWidth : w, minHeight : h, width : w, height : h, fontSize : s });

        let fontIcon = icon.indexOf("ion-") == 0 || icon.indexOf("fa") == 0;
        if (fontIcon) {
            let iconClassName = "icon-content " + icon;
            //ikonki FontAwesome
            if (icon.indexOf("fa-") != -1) {
                iconClassName += " fa";
            }

            return  <div {...props}>
                        <div className={iconClassName} style={this.props.iconStyle} />
                    </div>;
        }
        else if (icon.toLowerCase().indexOf(".svg") > -1 && this.props.embedSVG) {
            //TODO ReactSVG bardzo obciąża - zoptymalizować
            //JG: Embedowanie kodu SVG (umożliwia używanie CSS np. do kolorowania ikonek)
            let svgStyle = this.props.iconStyle ? Object.assign({}, this.props.iconStyle) : {};
            let isInverted = this.props.modifier === "inverted";
            svgStyle.width = w - (isInverted ? 6 : 0);
            svgStyle.height = h - (isInverted ? 6 : 0);
            if (isInverted) {
                props['style']['borderRadius'] = w/2;
            }
            return <div {...props}><ReactSVG src={icon} afterInjection={(error, svg) => {
                if (error) {
                    console.error("Icon SVG error", error);
                }
                //console.log("Icon > SVG:", svg);
            }} wrapper="div" className="svg-wrapper" style={svgStyle} /></div>
        } else {
            return <div {...props}><img width={w} height={h} src={icon} style={this.props.iconStyle} /></div>
        }
    }
}

Icon.defaultProps = {
    ...UIComponent.defaultProps,
    size : 24,
    width : 0,
    height : 0,
    rotate : 0,
    embedSVG : false,
    icon : null,
    spin : false, //czy ikonka ma się obracać
    iconStyle : null,
    modifier: "", //inline, white, inverted
};
