import React from "react";
import ReactDOM from "react-dom";
import BaseField from "./BaseField";
import ValidationUtil from "../../../../www/util/ValidationUtil";

export default class InputField extends BaseField {

    constructor(props){
        super(props);

        this.htmlPropsList.push("placeholder", "type", "maxLength", "pattern", "autocomplete");
        this.customPropsList.push("focusOnStart");

        this.internalInput = null;
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    _getValidProps() {
        super._getValidProps();

        this.validProps["onChange"] = this.onChange;
        this.validProps['onKeyDown'] = this.onKeyDown;

        return this.validProps;
    }

    validate() {
        const errors = super.validate();

        //TODO Zrobić walidację html https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
        if (this.props.type === "email" && String(this.state.value).length > 0) {
            if (!ValidationUtil.isValidEmail(this.state.value)) {
                errors.push("Podaj poprawny adres e-mail");
            }
        }

        return errors;
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this._onSubmit();
        }
    }

    _onSubmit() {
        if (this.context.onSubmit) {
            this.context.onSubmit();
        }
    }

    renderInput() {
        return  <input type={this.props.type} ref={(f) => this._setInputField(f)} {...this._getValidProps()} />
    }

    _setInputField(f) {
        if (!this.internalInput) {
            this.internalInput = ReactDOM.findDOMNode(f);
            if (this.internalInput && this.props.focusOnStart) {
                setTimeout(() => {
                    this.internalInput.focus(); //JG: Focusowanie do pola - działa tez na nowszych IOSach
                }, 100);
            }
        }
    }
}

InputField.defaultProps = {
    ...BaseField.defaultProps,
    type : "text", //typ inputa
    modifier : "default", //default, search
    focusOnStart : false, //czy focusujemy pole na starcie
    onSubmit : null, //odpalanie na Enter w polu
    //changeDelay : 0, //TODO odpalanie zmian po zadanym czasie (np. przy searchowaniu)
};