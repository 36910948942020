import MathUtil from "./MathUtil"
import FieldFormats from "../fields/FieldFormats"
import StringUtil from "./StringUtil"
require("date-format-lite");

export default class DataFormatter {

    /**
     *
     * @param num
     * @param precision
     * @param detectThousandsSeparator
     * @param default0
     * @returns {*}
     */
    static parseNumber(num, precision = 2, detectThousandsSeparator = true, default0 = false) {

        if (num === null || num === undefined || num === "" || String(num) == "NaN") {
            return default0 ? 0 : NaN;
        }

        //JG: dla liczb ułamkowych nie możemy zaokrąglać i przycinać
        //poprawka: https://app.asana.com/0/766281783386960/1122593438368735
        if (DataFormatter.comaToDot(String(num)).indexOf("0.") == 0) {
            detectThousandsSeparator = false;
            precision = -1;
        }

        if (typeof(num) != "number") {
            //AW to chyba było do oddzielania tysięcy kropkami i spacjami, przed mergem to działało, ale może popsuć coś w fillupie, więc nie wrzucam
            //v = v.replace(/\D(?=[0-9][0-9][0-9])/g,'')

            //JG: Wycinamy jeszcze nieobsługiwane znaki (jak była wartość wklejona a nie wpisana)
            num = String(num).replace(/[^0-9\.,-]/g, '');

            num = DataFormatter.comaToDot(String(num));

            //JG: Jeśli wpisujemy więcej niż dwa znaki po przecinku to traktujemy jako integer (tak jak w AS3)
            //SN: precision != -1 -> bo nie dało się wpisywać wiecej niz 2 miejsca po przecinku https://app.asana.com/0/766281783386960/1130418069373815
            if (precision != -1 && detectThousandsSeparator && num.indexOf(".") > -1 && num.split(".")[1].length > Math.max(2, precision)) {
                num = num.replace(/\./g, '');
            }
            num = num.replace(/\s/g, ''); //usuwamy spacje
            //console.log("num to parse", num);
            num = parseFloat(num);
        }

        //console.log("num float", num);
        if (isNaN(num)) {
            return default0 ? 0 : NaN;
        }

        if (precision > -1) {
            num = MathUtil.round(num, precision);
        }

        //console.log("num round", num);
        return num;
    }

    static isNaNorZero(v) {
        return v == 0 || String(v) == "NaN" || isNaN(v) || String(v) == "undefined";
    }

    static parseDefaultNumber(num, detectThousandSeparator = true) {
        return DataFormatter.parseNumber(num, 2, detectThousandSeparator, true);
    }

    /**
     * BI - przenoszę parsowanie daty z AS3
     *
     * Próbuje parsować wartość nieznanego typu na obiekt Date.
     * @param val
     * @param f (np. DD-MM-YYYY) format jeśli wiadomo że data to string z takim formatem. jeśli format nie jest znany program sprawdzi domyślne formaty
     * @return Date
     */
    static parseDate(val, f = null) {

        if (!val) {
            return null;
        }

        let result = null;

        if (val instanceof Date) {
            result = val;
        }

        if (typeof(val) === "number") {
            //TODO JG UWAGA! Jeśli data była tworzona na serwerze z inną strefą czasową to trzeba będzie przesunąć do naszej strefy
            result = new Date(val);

            // TODO DO NAAPRAWY GLOBALNIE, na razie Hack i tymczasowe rozwiązanie po rozmowie z Adamem (nie wiadomo czy coś innego nie zepsuje)
            // Temat związany z wysłaniem daty do fillup serwera po pobraniu e-deklaracji przestawia się data
            // taka data działa: 11-05-1941, a taka nie: 11-03-1941
            // ALBO z tego PESEL 41032004756 w JS powstwaje 20-03-1941 (np PIT-OP) a wraca w xml edeklaracji 19-03-1941
            // ? bo w JS poniżej coś koło 1960 roku zawsze jest GMT+02 niezależnie od miesiąca, a w AS3 zawsze jest GMT+2 dla kwiecień-wrzesień,
            // i GMT+01 dla październik-marzec, a dla 1410 roku w AS3 nadal jest +02/+01 zależnie od miesiąca, a w JS... +124 :)

            //BI PONOWNIE - BROBLEM DOTYCZY TYLKO daty jako number, więc nie róbcie więcej tego hacka globalnie.
            if (result) {
                result.setHours(6);
            }
        }

        if (typeof(val) === "string") {
            let format = DataFormatter.dateFormat(f);
            try {
                if (format && format !== "") {
                    result = DataFormatter.stringToDate(val, format, false);
                }

                if (!result || !format || format === "") { //jeśli format nie przypasował, to może ktoś wpisał w innym formacie?
                    for (var prop in FieldFormats.FORMAT_DATE) {
                        var tempFormat = DataFormatter.dateFormat(FieldFormats.FORMAT_DATE[prop]);
                        var tempDate = DataFormatter.stringToDate(val, tempFormat, false);
                        if (tempDate) {
                            result = tempDate;
                            break;
                        }
                    }
                }
            }
            catch (er) {
                console.error("DataFormatter.parseDate", "Błąd parsowania daty", val, format, er);
            }
        }

        //JG: Sprawdzamy jeszcze czy data nie jest z kosmosu...
        if (result && (result instanceof Date) && (result.getFullYear() <= 1800 || result.getFullYear() > 2100)) {
            console.log("DataFormatter.parseDate", "Data nie jest z przedziału 1800 - 2100", result);
            return null;
        }

        return result;
    }

    /**
     *  Mod By BI
     *
     *  Parses a String object that contains a date, and returns a Date object corresponding to the String.
     *  The <code>inputFormat</code> argument contains the pattern in which the <code>valueString</code> String is formatted.
     *  It can contain <code>"M"</code>,<code>"D"</code>,<code>"Y"</code>, and delimiter and punctuation characters.
     *
     *  <p>The function does not check for the validity of the Date object. If the value of the date, month, or year is NaN, this method returns null.</p>
     *
     *  <p>For example:
     *  <pre>var dob:Date = DateField.stringToDate("06/30/2005", "MM/DD/YYYY");</pre>
     *  </p>
     *
     *  @param valueString {String} date value to format.
     *  @param inputFormat {String} defining the date format.
     *  @param showFormatError
     *  @return {Date}
     */
    static stringToDate(valueString, inputFormat, showFormatError = true) {
        // SN: usuwamy białe znaki jeżlei inputFormat pozwala https://app.asana.com/0/1129472501632620/1131438022038827
        if (inputFormat && valueString && !(/\s/.test(inputFormat))) {
            valueString = valueString.replace(/\s/g, '');
        }

        //BI najpierw sprawdzenie czy valueString pasuje do wzorca inputFormat
        if (!valueString || valueString.length != inputFormat.length) {
            return null;
        }
        var i;
        var n = inputFormat.length;
        for (i = 0; i < n; i++) {
            var formatChar = inputFormat.charAt(i);
            var valueChar = valueString.charAt(i);

            //jesli valueChar to nie cyfra to musi być identyczny znak jak w formacie. np '.' '/' lub '-'
            if (isNaN(valueChar) && valueChar != formatChar) { //https://www.w3schools.com/jsref/jsref_isnan.asp
                if (showFormatError) {
                    console.warn("DataFormatter.stringToDate data nie pasuje do wzorca valueString: " + valueString + ", inputFormat: " + inputFormat);
                }
                return null;
            }
        }

        var mask;
        var temp;
        var dateString = "";
        var monthString = "";
        var yearString = "";
        var hourString = "";
        var minuteString = "";
        var secondString = "";
        var j = 0;
        for (i = 0; i < n; i++, j++) {
            temp = "" + valueString.charAt(j);
            mask = "" + inputFormat.charAt(i);

            if (mask == "M") {
                if (isNaN(temp) || temp == " ") {
                    j--;
                }
                else {
                    monthString += temp;
                }
            }
            else if (mask == "D") {
                if (isNaN(temp) || temp == " ") {
                    j--;
                }
                else {
                    dateString += temp;
                }
            }
            else if (mask == "Y") {
                yearString += temp;
            }
            else if (mask == "h") {
                hourString += temp;
            }
            else if (mask == "m") {
                minuteString += temp;
            }
            else if (mask == "s") {
                secondString += temp;
            }
            else if (!isNaN(temp) && temp != " ") {
                console.log("DataFormatter.stringToDate data nie pasuje do wzorca. mask: " + mask + ", temp: " + temp);
                return null;
            }
        }

        temp = "" + valueString.charAt(inputFormat.length - i + j);
        if (!(temp == "") && (temp != " ")) {
            console.log("DataFormatter.stringToDate data nie pasuje do wzorca temp: " + temp + " valStr: " + valueString);
            return null;
        }

        //w formacie możemy pominąć dzień lub rok, wtedy trzeba przypisać domyślną wartość
        dateString = dateString == "" ? "1" : dateString;
        yearString = yearString == "" ? new Date().getFullYear() : yearString;

        var monthNum = DataFormatter.parseNumber(monthString);
        var dayNum = DataFormatter.parseNumber(dateString);
        var yearNum = DataFormatter.parseNumber(yearString);

        if (isNaN(yearNum) || isNaN(monthNum) || isNaN(dayNum)) {
            console.log('DataFormatter.stringToDate parsowanie daty nie poszło 1');
            return null;
        }

        if (yearString.length == 2 && yearNum < 70) {
            yearNum += 2000;
        }

        var newDate = new Date(yearNum, monthNum - 1, dayNum);
        if (dayNum != newDate.getDate() || (monthNum - 1) != newDate.getMonth()) {
            console.log('DataFormatter.stringToDate parsowanie daty nie poszło 2', newDate);
            return null;
        }

        return newDate;
    }


    /*
     *
     * FORMATOWANIE:
     *
     */

    /**
     * https://www.npmjs.com/package/format-number
     *
     * @param num
     * @param precision
     * @param integerSeparator
     */
    static formatNumber(num, precision = 2, integerSeparator = " ") {
        num = DataFormatter.parseNumber(num, precision);
        if (isNaN(num)) {
            return "";
        }
        var numberFormatter = require('format-number');
        var myFormat = numberFormatter({padRight: precision, round: precision > 0 ? precision : -1, decimal: ",", integerSeparator: integerSeparator, decimalsSeparator: ""});
        return myFormat(num);
    }

    static formatInteger(num) {
        return DataFormatter.formatNumber(num, 0);
    }

    static formatCurrencyNumber(num, currency = "zł", precision = 2) {
        return DataFormatter.formatNumber(num, precision) + " " + currency;
    }

    /**
     * Tego używamy:
     * https://www.npmjs.com/package/date-format-lite
     *
     * Można też spróbować: http://momentjs.com/
     *
     * @param date
     * @param format
     */
    static formatDate(date, format = "YYYY-MM-DD") {
        if(!date) { //jeśli dostanie null, to niech nie zwraca 1970-01-01
            return "";
        }
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        return date ? date.format(format) : "?";
    }

    /**
     * Pobiera datę w formacie Number (timestamp), Date lub String i konwertuję na datę w String w formacie YYYY-MM-DD zapisywanym do bazy, XML i JSON.
     *
     * @param value
     * @return
     */
    static toDatabaseDate(value) {
        //TODO JG Przeniesc do parseDate jescze
        //Szybka walidacja formatu (jeśli podajemy String to musi być zawsze YYYY-MM-DD)
        if (value && typeof(value) === 'string') {
            if (DataFormatter.DATE_FORMAT_YYYYMMDD.exec(value)) {
                return value;
            }
            else {
                error("DateUtil.createStringDate", "Invalid date:", value);
                return null;
            }
        }

        if (value) {
            const date = DataFormatter.parseDate(value, DataFormatter.DEFAULT_DATABASE_DATE_FORMAT);
            return date.getFullYear() + "-" + StringUtil.leadZero(date.getMonth() + 1) + "-" + StringUtil.leadZero(date.getDate());
        }

        return '';
    }

    /**
     * Konwertuje datę w formacie Number (timestamp) lub String YYYY-MM-DD na obiekt Date w lokalnej strefie czasowej.
     *
     * @param s
     * @return
     */
    static fromDatabaseDate(s) {
        return DataFormatter.parseDate(s, DataFormatter.DEFAULT_DATABASE_DATE_FORMAT);
    }

    static formatDateTime(date, format = "YYYY-MM-DD hh:mm") {
        return DataFormatter.formatDate(date, format);
    }

    static formatDateBirth(date) {
        return DataFormatter.formatDate(date, "DD-MM-YYYY");
    }

    /**
     * Przykład: http://public.fillup.pl/services/Kody_pocztowe/pna.php?kody=60-143
     * @param items
     * @returns {*}
     */
    static formatPostCodes(items) {
        if (!items || items.length == 0) {
            return null;
        }
        var r = {};
        var validCodes = [];
        var miejscowosci = [];
        var poczty = [];
        var powiaty = [];
        var gminy = [];
        var ulice = [];
        for (var prop in items) {
            var item = items[prop];
            validCodes.push(item);
            if (poczty.indexOf(item.poczta) == -1) {
                poczty.push(item.poczta);
            }
            if (miejscowosci.indexOf(item.miejscowosc) == -1) {
                miejscowosci.push(item.miejscowosc);
            }
            if (powiaty.indexOf(item.powiat) == -1) {
                powiaty.push(item.powiat);
            }
            if (gminy.indexOf(item.gmina) == -1) {
                gminy.push(item.gmina);
            }
            if (ulice.indexOf(item.ulica) == -1) {
                ulice.push(item.ulica);
            }
        }
        var id = 0;
        for (var prop in items) {
            var item = items[prop];
            if (miejscowosci.length == 1) {
                items[prop].title = item.ulica ? 'ul. ' + item.ulica : item.miejscowosc;
            } else {
                items[prop].title = item.ulica ? 'ul. ' + item.ulica + ', ' + item.miejscowosc : item.miejscowosc;
            }
            items[prop].id = id++;

        }

        if (validCodes.length == 0) {
            return null;
        }
        var r0 = validCodes[0];
        r.Wojewodztwo = r0.wojewodztwo;
        if (powiaty.length == 1) {
            r.Powiat = r0.powiat;
        } else {
            r.Powiat = "";
        }
        if (gminy.length == 1) {
            r.Gmina = r0.gmina;
        } else {
            r.Gmina = "";
        }

        if (poczty.length == 1) {
            r.Poczta = r0.poczta;
        } else {
            r.Poczta = "";
        }
        if (miejscowosci.length == 1) {
            r.Miejscowosc = r0.miejscowosc;
        }
        else {
            r.Miejscowosc = "";
            miejscowosci.sort();
        }
        if (ulice.length == 1) {
            r.Ulica = r0.ulica;
        } else {
            r.Ulica = "";
        }
        /*items.sort(function(a,b) {
         return ((a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0));
         });*/
        r._items = items;
        return r;
    }

    static dateFormat(format) {
        var result = format;

        if (result == null || result == '') {
            //console.log("Używam domyślnego formatu daty: " + DataFormatter.FILLUP_DEFAULT);
            return DataFormatter.DEFAULT_DATE_FORMAT;
        }

        result = (result == FieldFormats.FORMAT_DATE_ZUS) ? 'DDMMYYYY' : result;

        result = result.replace('rrrr', 'YYYY');
        result = result.replace('rr', 'YY');
        result = result.replace('dd', 'DD');
        result = result.replace('mm', 'MM');
        result = result.replace('mi', 'mm');

        return result;
    }

    /**
     * Zwraca dostępne formaty daty (przekonwertowane.
     */
    static dateFormats() {
        if (!FieldFormats.DATE_FORMATS) {
            FieldFormats.DATE_FORMATS = [];
        }
        for (var prop in FieldFormats.FORMAT_DATE) {
            var newFormat = DataFormatter.dateFormat(FieldFormats.FORMAT_DATE[prop]);
            if (FieldFormats.DATE_FORMATS.indexOf(newFormat) == -1) {
                FieldFormats.DATE_FORMATS.push(newFormat);
            }
        }

        return FieldFormats.DATE_FORMATS;
    }

    static formatInputValue(date, fieldFormat) {

        let parsedDate = DataFormatter.parseDate(date, fieldFormat);

        if (parsedDate) {
            let format = DataFormatter.dateFormat(fieldFormat);
            return parsedDate.format(format);
        }

        return "";
    }


    /*
     *
     * POZOSTAŁE FUNKCJE:
     *
     */

    /**
     * nieużywane = WYWALIĆ???
     */
    static dotToComa(t) {
        return t ? t.replace(/\./g, ",") : "";
    }

    static comaToDot(t) {
        return t ? t.replace(/,/g, ".") : "";
    }

    static clearURL(url) {
        url = url.replace("http://", "").replace("https://", "");
        if (url.indexOf("/") == url.length - 1) {
            url = url.substr(0, url.length - 1);
        }
        return url;
    }

    static clearBR(s) {
        s = s.split("<br>").join("<div class='br' />");
        s = s.split("<br/>").join("<div class='br' />");
        return s;
    }

    /**
     * Zwraca podany rzeczownik w odpowiednim przypadku.
     * Np. 1 dzień, 2 dni.
     *
     * Pozycje w tabeli forms:
     * 0 - dla 0 (np. 0 dni)
     * 1 - dla 1 ( np. 1 dzień)
     * 2 - dla 2-4 (np. 3 dni)
     * 3 - dla 5-21 (np. 20 dni)
     *
     * forms - np. ["punktów", "punkt", "punkty", "punktów"]
     * */
    static getValidForm(num, forms, includeNumber = true) {
        var t1 = includeNumber ? num + " " + forms[0] : forms[0];
        var t2 = includeNumber ? num + " " + forms[1] : forms[1];
        var t3 = includeNumber ? num + " " + forms[2] : forms[2];
        var t4 = includeNumber ? num + " " + forms[3] : forms[3];

        //0
        if (num == 0) return t1;

        //1
        if (num == 1) return t2;

        //2-4
        if (num >= 2 && num <= 4) {
            return t3;
        }

        //5-21
        if (num >= 5 && num <= 21) {
            return t4;
        }

        var mod = num % 10;
        if (mod == 2 || mod == 3 || mod == 4) return t3;

        return t4;
    }

    static getLiczebnikPorzadkowyDopelniacz(num) {
        var l = ["zerowego", "pierwszego", "drugiego", "trzeciego", "czwartego", "piątego", "szóstego", "siódmego", "ósmego", "dziewątego", "dziesiątego",
            "jedenastego", "dwunastego", "trzynastego", "czternastego", "piętnastego", "szesnastego", "siedemnastego", "osiemnastego", "dziewiętnastego", "dwudziestego"];
        return l[num];
    }

    static getLiczebnikPorzadkowyDopelniaczFem(num) {
        var l = ["zerowej", "pierwszej", "drugiej", "trzeciej", "czwartej", "piątej", "szóstej", "siódmej", "ósmej", "dziewątej", "dziesiątej",
            "jedenastej", "dwunastej", "trzynastej", "czternastej", "piętnastej", "szesnastej", "siedemnastej", "osiemnastej", "dziewiętnastej", "dwudziestej", "dwudziestej pierwszej", "dwudziestej drugiej",
            "dwudziestej trzeciej", "dwudziestej czwartej", "dwudziestej piątej", "dwudziestej szóstej"];
        return l[num];
    }

    static zaokr(n) {
        return Math.round(n * 100) / 100;
    }

    /**
     * nieużywane = WYWALIĆ
     */
    static zaokrPositive(n) {
        if (n < 0) {
            return 0;
        }
        return Math.round(n * 100) / 100;
    }

    static zaokrDecimal(n) {
        if (n == undefined) {
            return 0;
        }
        return DataFormatter.parseNumber(n, 0);
    }

    /**
     * BI funkcja zamienia pierwsze 6 znaków podanego numeru PESEL na datę urodzenia. PESEL NIE JEST WALIDOWANY, chociaż dla zabawy można dodać :)
     * @param pesel
     * @return data urodzenia
     */
    static dateFromPESEL(pesel) {
        if (!pesel) {
            return null;
        }
        //console.log("DataFormatter.dataFromPESEL [" + pesel + "]");

        var year = 0;
        var month = 0;
        var day = 0;
        /**
         * na podstawie excela dla lat 1900 - 2099
         * =DATA(ZAOKR.GÓRA(FRAGMENT.TEKSTU(A2;3;2)/20;0)+18&LEWY(A2;2);MOD(FRAGMENT.TEKSTU(A2;3;2);20);FRAGMENT.TEKSTU(A2;5;2))
         */
        var yearStr = pesel.substr(0, 2);
        var monthStr = pesel.substr(2, 2);
        //console.log("monthStr [" + Number(monthStr) + "]", parseInt());

        //dzień obliczyć najłatwiej, jest podany jawnie:
        day = Number(pesel.substr(4, 2));
        //miesiąc. w zależności od roku, do miesiąca dodaje się liczby (2000-2099 -> 20) (2100-2199 -> 40) (2200-2299 -> 60) (1800-1899 -> 80)
        month = Number(monthStr) % 20;

        //rok. sprawdzić przedrostek na podstawie miesiąca.
        var o = {0: '19', 1: '20', 2: '21', 3: '22', 4: '18'};
        //console.log("o", o);
        var mod = Math.floor(Number(monthStr) / 20);
        yearStr = o[mod] + yearStr;

        year = Number(yearStr);
        var r = new Date(year, month - 1, day); //miesiące są numerowane od zera

        //JG: Dodatkowe zabezpieczenie na błędne PESELe - np. 29035706325, 23456789101
        //https://secure.fillup.pl/node/11917332
        if (day != r.getDate() || (month - 1) != r.getMonth()) {
            return null;
        }

        return r;
    }

    /**
     * deprecated = WYWALIĆ
     */
    static getTimeToDate(from, to) {
        if (from > to || !from || !to) {
            return null;
        }
        var diff = to.getTime() - from.getTime();

        var one_day = 1000 * 60 * 60 * 24;
        var one_hour = 1000 * 60 * 60;
        var one_minute = 1000 * 60;
        var one_second = 1000;

        var days = Math.abs(Math.floor(diff / one_day));

        diff -= days * one_day;
        var hours = Math.abs(Math.floor(diff / one_hour));

        diff -= hours * one_hour;
        var minutes = Math.abs(Math.floor(diff / one_minute));

        diff -= minutes * one_minute;
        var seconds = Math.abs(Math.floor(diff / one_second));

        return {days: days, hours: hours, minutes: minutes, seconds: seconds};
    }

    /**
     *
     * @param d {Date}
     * @param emptySign {string}
     * @param includeSeconds {Boolean}
     * @returns {String}
     */
    static getDefaultDateView(d, emptySign = "", includeSeconds = false) {
        if (d == null) {
            return emptySign;
        }
        /** @type {String} */
        var r = DataFormatter.getDefaultDateOnlyView(d) + " " + StringUtil.leadZero(d.getHours()) + ":" + StringUtil.leadZero(d.getMinutes());
        if (includeSeconds) {
            r += ":" + StringUtil.leadZero(d.getSeconds());
        }
        return r;
    }

    /**
     * Domyślny widok samej daty. YYYY-MM-DD. Działa dużo szybciej niż przez flexowy DateFormatter
     * @param d {Date}
     * @return {String}
     */
    static getDefaultDateOnlyView(d) {
        return d.getFullYear() + "-" + StringUtil.leadZero(d.getMonth() + 1) + "-" + StringUtil.leadZero(d.getDate());
    }

    /**
     * BI dodaję ALIAS żeby łatwo się przenosiło kod z as3
     * @param date
     * @param format
     */
    static getFormattedDate(date, format) {
        return DataFormatter.formatDate(date, format);
    }

    static getMonthNames() {
        return ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];
    }

    static getMonthsDataProvider(addEmptyValue = false) {
        const d = [];
        const names = DataFormatter.getMonthNames();
        let i = 1;
        for (let prop in names) {
            let p = { id : i, title : names[prop] };
            d.push(p);
            i++;
        }
        if (addEmptyValue) {
            d.unshift({ id : null, title : ""});
        }
        return d;
    }

    static formatValue(value, numberPrecision = 2) {
        if (value instanceof Date) {
            return DataFormatter.formatDate(value);
        } else if (typeof(value) == "number") {
            return DataFormatter.formatNumber(value, numberPrecision, ',');
        } else {
            return value ? String(value) : "";
        }
    }

    //#UNITTEST
    static parseStringDate(value, checkISO = true, checkYYYYMMDD = false) {
        if (!value || typeof(value) !== "string") {
            return null;
        }
        if (checkISO && DataFormatter.DATE_FORMAT_ISO.exec(value)) {
            return new Date(value);
        }
        if (checkYYYYMMDD && DataFormatter.DATE_FORMAT_YYYYMMDD.exec(value)) {
            return DataFormatter.stringToDate(value, "YYYY-MM-DD");
        }
        return null;
    }
}

DataFormatter.FORMAT_DEKLARACJE = ["deklaracji", "deklarację", "deklaracje", "deklaracji"];
DataFormatter.FORMAT_FROMULARZE = ["formularzy", "formularz", "formularze", "formularzy"];
DataFormatter.FORMAT_WYPELNIONE_DEKLARACJE = ["wypełnionych deklaracji", "wypełnioną deklarację", "wypełnione deklaracje", "wypełnionych deklaracji"];
DataFormatter.FORMAT_ATTACHMENTS = ["załączników", "załącznik", "załączniki", "załączników"];
DataFormatter.FORMAT_BLEDNYCH_POL = ["błędnych pól", "błędne pole", "błędne pola", "błędnych pól"];
DataFormatter.FORMAT_BLAD = ["błędów", "błąd", "błędy", "błędów"];

DataFormatter.DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

DataFormatter.FORMAT_DAYS = ["dni", "dzień", "dni", "dni"];
DataFormatter.FORMAT_HOURS = ["godzin", "godzinę", "godziny", "godzin"];
DataFormatter.FORMAT_MINUTES = ["minut", "minutę", "minuty", "minut"];
DataFormatter.FORMAT_SECONDS = ["sekund", "sekundę", "sekundy", "sekund"];
DataFormatter.FORMAT_MONTHS = ["miesięcy", "miesiąc", "miesiące", "miesięcy"];
DataFormatter.FORMAT_WPIS = ["wpisów", "wpis", "wpisy", "wpisów"];
DataFormatter.FORMAT_ATTACHMNETS = ["załączników", "załącznik", "załączniki", "załączników"];
DataFormatter.FORMAT_FORMS = ["formularzy", "formularz", "formularze", "formularzy"];
DataFormatter.FORMAT_FORMS2 = ["formularzy", "formularzy", "formularzy", "formularzy"];
DataFormatter.FORMAT_PRINTED = ["wydrukowanych", "wydrukowany", "wydrukowane", "wydrukowanych"];
DataFormatter.FORMAT_DEKLARACJE = ["deklaracji", "deklarację", "deklaracje", "deklaracji"];
DataFormatter.FORMAT_DEKLARACJE2 = ["deklaracji", "deklaracji", "deklaracji", "deklaracji"];
DataFormatter.FORMAT_POINTS = ['punktów', 'punkt', 'punkty', 'punktów'];
DataFormatter.FORMAT_PIT = ['PITów', 'PITa', 'PITy', 'PITów'];
DataFormatter.FORMAT_KUPIONE_PIT = ['zakupionych PITów', 'zakupionego PITa', 'zakupione PITy', 'zakupionych PITów'];
DataFormatter.FORMAT_PAYED_FORMS = ['płatnych formularzy', 'płatny formularz', 'płatne formularze', 'płatnych formularzy'];
DataFormatter.FORMAT_SELECTED_FORMS = ['wybranych formularzy', 'wybrany formularz', 'wybrane formularze', 'wybranych formularzy'];
DataFormatter.FORMAT_FREE_POINTS = ['darmowych punktów', 'darmowy punkt', 'darmowe punkty', 'darmowych punktów'];
DataFormatter.FORMAT_ERRORS = ['błędów', 'błąd', 'błędy', 'błędów'];
DataFormatter.FORMAT_INFOS = ['informacji', 'informacja', 'informacje', 'informacji'];
DataFormatter.FORMAT_COMPANIES = ['firm', 'firmy', 'firm', 'firm'];
DataFormatter.FORMAT_COMPANIES2 = ['firm', 'firmę', 'firmy', 'firm'];
DataFormatter.FORMAT_ELEMENTY = ['elementów', 'element', 'elementy', 'elementów'];
DataFormatter.FORMAT_WAZNE = ['ważnych', 'ważny', 'ważne', 'ważnych'];
DataFormatter.FORMAT_EDEKLARACJE_SENT = ["wysłanych e-Deklaracji", "wysłana e-Deklaracja", "wysłane e-Deklaracje", "e-wysłanych Deklaracji"];
DataFormatter.FORMAT_ZNAKI = ['znak','znak', 'znaki', 'znaków'];


DataFormatter.DATE_FORMAT_ISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/; //JG: Data w formacie ISO np. 2020-08-26T15:18:38.201044+02:00
DataFormatter.DATE_FORMAT_YYYYMMDD = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/; //JG: Data w formacie YYYY-MM-DD
DataFormatter.DEFAULT_DATABASE_DATE_FORMAT = 'YYYY-MM-DD';

//SN: Dodano parametr checkStringValue - błąd: https://app.asana.com/0/1129472501632620/1133656020542132 //BI ten parametr jest wg. mnie skopany
//jeżeli checkStringValue == true ->  wówczas dowolny tekst jest traktowany jako true np: "mazowieckie" (oprócz tekstów "true", "false")
//jeżeli checkStringValue == false -> wówczas dowolny tekst jest traktowany jako false (oprócz tekstów "true", "false")
window.parseBoolean = function (v, checkStringValue = true) {
    return v !== false && v != "false" && v != "" && v != undefined && v != null && v != "0" && (checkStringValue || !isNaN(v) || (typeof(v) == "string" && v.toLowerCase() == "true"));
};

window.safeParseInt = function (v) {
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
    let r = parseInt(String(v), 10);
    return isNaN(r) ? 0 : r;
};

window.safeDecodeURI = function (v) {
    try {
        return decodeURI(v);
    }
    catch (er) {
        return unescape(v);
    }
};

Date.prototype.localDateToUTC = function (h = 0, m = 0, s = 0) {
    return Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), h, m, s);
};

Date.prototype.localDateFromUTC = function (h = 0, m = 0, s = 0) {
    return new Date(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate(), h, m, s);
};

//JG: Potrzebne tylko do działania pól funkcyjnych, które w AS3 wpisują Stringa a tu Number. FormFields.przelicz - np. pole pI_73 w GUS F-01/s (2016)
Number.prototype.replace = function () {
    var r = String(this).replace.apply(this, arguments);
    console.warn("Number.prototype.replace", this, r);
    return r;
};

/*
 JG: Wersja match działająca analogicznie do String.match w AS3 (potrzebne do funkcji w FormFields)

 TODO Do testow auto:
 'PL'.safeMatch('^P[A-KM-Z]|[A-OQ-Z][A-Z]$') == []
 'EN'.safeMatch('^P[A-KM-Z]|[A-OQ-Z][A-Z]$') == ["EN"]

 '123'.safeMatch('\\d{1,5}/\\d{1,5}') == []
 '1/2'.safeMatch('\\d{1,5}/\\d{1,5}') == ["1/2"]

 'abc'.safeMatch('^[0-9]+$') == []
 '12'.safeMatch('^[0-9]+$') == ["12"]
 */

String.prototype.safeMatch = function () {
    if (this) {
        var r = this.match.apply(this, arguments);
        if (r) {
            var res = [];
            for (var i = 0; i < r.length; i++) {
                res.push(r[i]);
            }
            return res;
        }
    }
    return [];
};

window.DataFormatter = DataFormatter;