export default class URLUtil {

    /**
     * Konwertuje obiekt na parametry w URL.
     *
     * @param params        { param1 : 1, param2 : 3} lub obiekt FormData
     * @param encodeURIComponents
     * @returns {string}    '?param1=1&param2=3'
     */
    //#UNITTEST
    static formatQueryParams(params, encodeURIComponents = true) {
        if (params instanceof FormData) {
            return URLUtil.formatFormDataParams(params);
        }
        const r = [];
        for (let prop in params) {
            if (params[prop]) {
                if (encodeURIComponents) {
                    r.push(encodeURIComponent(prop) + "=" + encodeURIComponent(params[prop]));
                } else {
                    r.push(prop + "=" + params[prop]);
                }
            }
        }
        return r.length > 0 ? "?" + r.join("&") : "";
    }

    /**
     * Konwertuje parametry w URL na obiekt.
     *
     * @param search      np. '?param1=1&param2=3'
     * @returns {string}  { param1 : 1, param2 : 3}
     */
    //#UNITTEST
    static parseQueryParams(search) {
        const params = {};
        if (!search) {
            return params;
        }
        if (search.indexOf("?") === 0) {
            search = search.substr(1);
        }
        const s = search.split("&");
        for (let param of s) {
            let p = param.split("=");
            params[p[0]] = p[1];
        }
        return params;
    }

    /**
     * Konwertuje obiekt FormData na parametry w URL.
     *
     * UWAGA: W IE nie zadziała - trzebaby dodać https://github.com/github/form-data-entries
     *
     * @param   formData    obiekt FormData
     * @param   encodeURIComponents
     * @returns {string}    '?param1=1&param2=3'
     */
    //#UNITTEST
    static formatFormDataParams(formData, encodeURIComponents = true) {
        const params = [...formData.entries()]; //to samo co Array.from(formData.entries())
        const r = [];
        for (let param of params) {
            if (String(param[1])) {
                if (encodeURIComponents) {
                    r.push(encodeURIComponent(param[0]) + "=" + encodeURIComponent(param[1]));
                } else {
                    r.push(param[0] + "=" + param[1]);
                }
            }
        }
        return r.length > 0 ? "?" + r.join("&") : "";
    }

    /**
     * Pobiera URL z dodanymi parametrami GET.
     * Przykłady w URLUtil.test.js
     *
     * @param url           Url do uzycia
     * @param addParams     Parametry podawane w kolejnych argumentach
     * @returns {string}    Url z poprawnie dodanymi parametrami GET
     */
    static getURLWithParams(url, ...addParams) {
        return url + (url.indexOf("?") > -1 ? "&" : "?") + addParams.join("&");
    }
}

