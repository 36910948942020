
export default class SiteConfig {

    static RECAPTCHA_KEY = '6LfCL78aAAAAAIZHRSR-MSuUlqp-RHwjtzD0mfFu';

    static API_PRODUCTS_OFFERS = '/api/products/offers/';

    static RODO_HEADER = "Informacja o Administratorze danych osobowych";

    static ORDER_ACCEPT_TEXT_1 = "Zapoznałem(am) się z <a href='/informacje/zasady-wynajmu/' target='_blank'>Zasadami Wynajmu</a> oraz akceptuję postanowienia w nich zawarte w całości. Wyrażam wolę zawarcia Umowy Najmu za pośrednictwem elektronicznych środków przekazu oraz wyrażam zgodę na dostarczenie faktury/rachunku wyłącznie drogą elektroniczną. Jestem świadomy(ma), iż zawarcie Umowy nastąpi dopiero w momencie potwierdzenia dostępności zamawianych przedmiotów najmu we wskazanym przeze mnie terminie oraz po opłaceniu łącznych kosztów zamówienia (koszt wynajmu + kaucja zwrotna) lub ustalonej indywidualnie zaliczki.";
    static ORDER_ACCEPT_TEXT_2 = "Zapoznałem(am) się z <a href='/informacje/regulamin/' target='_blank'>Regulaminem serwisu</a>, <a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a> oraz informacjami <a href='/informacje/rodo/' target='_blank'>RODO</a> i akceptuję ich warunki. Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z <a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a>. Informujemy, iż Państwa dane będziemy wykorzystywać wyłącznie na cele realizacji zamówień lub kontaktu a pełny zakres ich wykorzystania zawarliśmy w informacji <a href='/informacje/rodo/' target='_blank'>RODO</a>. Nie udostępniamy Państwa danych innym podmiotom, w szczególności nie przekazujemy ich na cele marketingowe.";
    static ORDER_ACCEPT_TEXT_3 = "Wyrażam zgodę na rozpoczęcie świadczenia usług przed upływem ustawowego 14-dniowego terminu do odstąpienia od umowy zawartej na odległość. Jestem świadomy, że po zaznaczeniu tej opcji utracę prawo do odstąpienia od umowy zawartej na odległość w ciągu 14 dni od dnia zawarcia umowy w przypadku zrealizowania przez Wynajmującego usługi w całości. Nadal będą mieli jednak Państwo prawo do odstąpienia od umowy, która nie została zrealizowana w całości, na zasadach określonych w <a href='/informacje/zasady-wynajmu/' target='_blank'>Zasadach Wynajmu</a>.<br/>";
    static ORDER_ACCEPT_TEXT_4 = "UWAGA! Jeżeli nie zaznaczą Państwo tej opcji, będą mieli Państwo prawo do odstąpienia od umowy w całości w ciągu 14 dni od dnia złożenia zamówienia, jednak świadczenie usług najmu będziemy mogli rozpocząć dopiero po upływie 14-dniowego terminu od dnia zawarcia umowy.";
    static ORDER_RODO_TEXT = "Administratorem Państwa danych osobowych jest Mikołaj Gemel, Longinówka 25, 97-340 Longinówka, zarejestrowany w Centralnej Ewidencji Działalności Gospodarczej pod numerem NIP: 7712779986, z którym możesz skontaktować się pod adresem e-mail: <a href='mailto:kontakt@rentiko.pl'>kontakt@rentiko.pl</a> lub numerem telefonu: 797 796 690. Państwa dane będą przetwarzane w celu realizacji złożonego zamówienia tj. w celu wykonania umowy najmu, której są Państwo stroną (art. 6 ust. 1 lit. b RODO). Przysługuje Państwu prawo do dostępu do swoich danych, sprostowania danych, przeniesienia danych oraz ograniczenia przetwarzania lub usunięcia danych o ile ograniczenia tych uprawnień nie będą wynikały z obowiązujących przepisów prawa. Aby dowiedzieć się więcej o przetwarzaniu danych osobowych zapoznaj się z naszą <b><a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a></b> i informacjami <b><a href='/informacje/rodo/' target='_blank'>RODO</a></b>.";

    static CONTACT_ACCEPT_TEXT = "Zapoznałem(am) się z <a href='/informacje/regulamin/' target='_blank'>Regulaminem serwisu</a>, <a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a> oraz informacjami <a href='/informacje/rodo/' target='_blank'>RODO</a> i akceptuję ich warunki. Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z <a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a>. Informujemy, iż Państwa dane będziemy wykorzystywać wyłącznie na cele realizacji zamówień lub kontaktu a pełny zakres ich wykorzystania zawarliśmy w informacji <a href='/informacje/rodo/' target='_blank'>RODO</a>. Nie udostępniamy Państwa danych innym podmiotom, w szczególności nie przekazujemy ich na cele marketingowe.";
    static CONTACT_RODO_TEXT = "Administratorem Państwa danych osobowych jest Mikołaj Gemel, Longinówka 25, 97-340 Longinówka, zarejestrowany w Centralnej Ewidencji Działalności Gospodarczej pod numerem NIP: 7712779986, z którym możesz skontaktować się pod adresem e-mail: <a href='mailto:kontakt@rentiko.pl'>kontakt@rentiko.pl</a> lub numerem telefonu: 797 796 690. Państwa dane będą przetwarzane w celu założenia Konta w serwisie i jego obsługi, tj. w celu wykonania umowy, której są Państwo stroną (art. 6 ust. 1 lit. b RODO). Przysługuje Państwu prawo do dostępu do swoich danych, sprostowania danych, przeniesienia danych oraz ograniczenia przetwarzania lub usunięcia danych o ile ograniczenia tych uprawnień nie będą wynikały z obowiązujących przepisów prawa. Aby dowiedzieć się więcej o przetwarzaniu danych osobowych zapoznaj się z naszą <b><a href='/informacje/polityka-prywatnosci/' target='_blank'>Polityką Prywatności</a></b> i informacjami <b><a href='/informacje/rodo/' target='_blank'>RODO</a></b>.";

}
