import React from "react";
import UIDUtil from "../util/UIDUtil";
import ObjectUtil from "../util/ObjectUtil";
import UIComponentUtil from "../../../ui/UIComponentUtil";

export default class ViewComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.bindedItems = [];
        this.initialized = false;
        this.disposed = false;
        this.displayName = null;
        this._name = null;
        this.uid = UIDUtil.createUIDSimple();
        this._onDataChange = this._onDataChange.bind(this);

        //console.log(this.name, this.uid, "constructor", this.context, this.props);
    }

    setState(v) {
        //JG: Jak komponent nie został zainicjowany - nie można odpalać setState (np. jak odpalimy w konstruktorze)
        if (!this.initialized) {
            //console.warn(this.name, this.uid, "setState", "component not initialized", "change: this.setState({ a : b }) > this.state.a = b");
            this.state = Object.assign(this.state, v);
            return;
        }

        //JG: Jak komponent został usunięty ze sceny to nie można odpalać setState
        if (!this.disposed) {
            //console.log(this.name, this.uid, "setState", v);
            super.setState(v);
        } else {
            console.warn(this.name, this.uid, "setState", "can't set state - component not mounted!");
        }
    }

    /**
     * Bindowanie do podanego obiektu.
     *
     * @param bindableItem      BindableObject
     * @param type              Typ eventy (np. 'change')
     * @param property          Paramatr, do którego bindujemy. Jak damy * to bindujemy do wszystkich
     */
    bindTo(bindableItem, type = "change", property = "*") {
        if (!bindableItem) {
            console.warn(this.name, this.uid, "bindTo", "Invalid dataItem");
            return;
        }

        if (!bindableItem.addEventListener) {
            console.warn(this.name, this.uid, "bindTo", "dataItem is not an EventDispatcher", bindableItem);
            return;
        }
        //console.log("DATA ITEM:", dataItem);
        if (!this.bindedItems) {
            this.bindedItems = [];
        }

        if (!property) {
            property = "*";
        }

        //JG: Sprawdzamy czy już nie ma identycznego bindingu
        for (let prop in this.bindedItems) {
            let binding = this.bindedItems[prop];
            if (binding.item === bindableItem && binding.type == type && binding.property == property) {
                return;
            }
        }

        //JG: Dodajemy binding
        this.bindedItems.push({ item : bindableItem, type : type, property : property });
        bindableItem.addEventListener(type, this._onDataChange);
    }

    /**
     * Subkomponenty mogą to overridować żeby sprawdzić czy faktycznie trzeba przerenderować
     */
    _onDataChange(event) {
        if (!event || !event.target) {
            return;
        }

        if (this.disposed) {
            console.warn(this.name, this.uid, "is disposed, data item: ", event, this.bindedItems);
        }

        let property = event.payload ? event.payload : "*";
        for (let prop in this.bindedItems) {
            let binding = this.bindedItems[prop];
            if (binding.item === event.target && binding.type == event.type && (binding.property == property || binding.property == "*")) {
                this.handleDataChange(event);
            }
        }
    }

    handleDataChange(event) {
        if (!this.disposed) {
            try {
                this.setState({});
            }
            catch (er) {
                console.log(this.name, this.uid, "can't update state", er.message, er.stack);
            }
        }
    }

    componentWillUnmount() {
        this.dispose();
        this.disposed = true;
    }

    _clearBindings() {
        if (this.bindedItems) {
            for (var prop in this.bindedItems) {
                var binding = this.bindedItems[prop];
                if (binding && binding.item) {
                    binding.item.removeEventListener(binding.type, this._onDataChange);
                }
            }
        }
        this.bindedItems = null;
    }

    _updateProps(p) {
        //override w podklasach
    }

    /**
     * JG: W tej chwili zostawiamy z dopiskiem UNSAFE (to nie oznacza, że jest niebezpieczne tylko, że może powodować spadek wydajności w niektórych sytuacjach.
     * Przerobienie tego wymaga przerobienia _updateProps we wszystkich subkomponentach - bardzo dużo do zrobienia i bardzo dużo się może popsuć.
     *
     * https://pl.reactjs.org/blog/2018/03/27/update-on-async-rendering.html
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.disposed) {
            //console.log(this.name, this.uid, "componentWillReceiveProps");
            try {
                this._updateProps(nextProps);
            }
            catch (er) {
                console.error(this.name, this.uid, "componentWillReceiveProps", er.message, er.stack);
            }
        }
    }

    /**
     * JG: W tej chwili zostawiamy z dopiskiem UNSAFE (to nie oznacza, że jest niebezpieczne tylko, że może powodować spadek wydajności w niektórych sytuacjach.
     * Przerobienie tego wymaga przerobienia _updateProps we wszystkich subkomponentach - bardzo dużo do zrobienia i bardzo dużo się może popsuć.
     *
     * https://pl.reactjs.org/blog/2018/03/27/update-on-async-rendering.html
     */
    UNSAFE_componentWillMount() {
        //console.log(this.name, this.uid, "componentWillMount", this.context, this.props);
        this.initialized = true;
        try {
            this.init(this.props);
        }
        catch (er) {
            console.error(this.name, this.uid, "componentWillMount", er.message, er.stack);
        }
    }

    componentDidMount() {
        this.componentMounted();
    }

    /**
     * Tutaj powinniśmy wrzucać asynchroniczne operacje ładowania itp.
     */
    componentMounted() {
        //override w podklasach
    }

    init(props) {
        this._updateProps(this.props);
    }

    dispose() {
        //Do overridowania w podklasach
        this._clearBindings();
    }

    getClassName(baseName = null, prefix = null) {
        var className = "";
        if (prefix) {
            className += prefix;
        }

        let c = ObjectUtil.getClassName(this);
        if (baseName && baseName !== c) {
            className += baseName + " ";
        }
        className += c;

        if (this.props.className) {
            className += " " + this.props.className;
        }
        return className;
    }

    get name() {
        if (this.displayName) {
            return this.displayName;
        }

        if (!this._name) {
            this._name = ObjectUtil.getClassName(this);
        }
        return this._name;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.pure) {
            return UIComponentUtil.checkShouldUpdate(this, this.props, nextProps, this.state, nextState);
        } else {
            return true;
        }
    }
}

ViewComponent.defaultProps = {
    classPrefix : null,
    pure : false //Jeśli komponent jest typu 'pure' to aktualizujemy go tylko jak zmieni się 'props' lub 'state'
};