import React from "react";
import DOMPurify from 'dompurify';
import ObjectUtil from "../../com/efile/epity/mobile/util/ObjectUtil";
import Icon from "../../com/efile/ui/Icon";

/**
 * Powiadomienie/informacja wewnątrz formularza.
 */
export default class InlineNotifyItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = { started : false, closed : false };
        this.onClose = this.onClose.bind(this);

        setTimeout(() => {
            if (!this.disposed) {
                this.setState({started : true});
            }
        }, 100);
    }

    onClose() {
        this.setState({closed : true});
    }

    render() {
        var helpTitle = this.props.title;
        var helpContent = this.props.content ? this.props.content : null;
        if (helpContent && helpTitle) {
            helpContent = "<h6>" + helpTitle + "</h6>" + helpContent;
        }

        var iconType = "";
        var size = this.props.iconSize;
        var s = {opacity : this.state.started ? 1 : 0};
        var iconS = {};
        switch (this.props.type) {
            case "message":
                iconType = Icons.NOTIFY_MESSAGE;
                break;
            case "info":
                iconType = Icons.NOTIFY_INFO;
                break;
            case "error":
                iconType = Icons.NOTIFY_ERROR;
                break;
            case "warning":
                iconType = Icons.NOTIFY_WARNING;
                break;
            case "warningBig":
                iconType = Icons.NOTIFY_WARNING;
                size = 48;
                iconS = {color: '#ff9933'};
                s.border = '3px solid #ff9933';
        }
        if (this.props.style) {
            s = ObjectUtil.assign(s, this.props.style);
        }
        var className = "InlineNotifyItem notify-" + this.props.type;
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.visible === false || this.state.closed) {
            className += " hidden";
        }
        if (this.props.modifier) {
            className += " " + this.props.modifier;
        }

        return  <div style={s} className={className}>
                    {this.props.showIcon ? (this.props.customIcon ? <div className="leftIcon">{this.props.customIcon}</div> : <Icon embedSVG size={size} className="leftIcon" style={iconS} icon={iconType}/>) : null}
                    {helpContent ? <div className="helpContent" dangerouslySetInnerHTML={{ __html : DOMPurify.sanitize(helpContent) }} style={this.props.additionalStyle} /> : null}
                    {!helpContent ? <div className="customContent">{this.props.children}</div> : null}
                    {this.props.closable ? <Icon embedSVG size={18} className="removeIcon" onClick={this.onClose} icon={Icons.CLOSE} /> : "" }
                </div>;
    }
}


InlineNotifyItem.defaultProps = {
    type : "info",
    visible : true,
    showChildrenOnly : false,
    closable : false,
    showIcon : true,
    customIcon : null,
    iconSize : 24,
    modifier : "",
    additionalStyle : { textAlign : "left" }
};

InlineNotifyItem.TYPE_INFO = "info";
InlineNotifyItem.TYPE_MESSAGE = "message";
InlineNotifyItem.TYPE_WARNING = "warning";
InlineNotifyItem.TYPE_WARNING_BIG = "warningBig";
InlineNotifyItem.TYPE_ERROR = "error";