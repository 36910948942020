import React from "react";
import UIComponent from "./UIComponent";

export default class HSpacer extends UIComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return  <div className={this.getClassName()} style={{width : parseInt(this.props.width)}} ></div>;
    }
}

HSpacer.defaultProps = {
    width : 10,
    modifier : "", //flex1
};