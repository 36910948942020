
export default class FieldFormats {
}

FieldFormats.FORMAT_KWOTA_ZAOKR_1_ZL = 'zaokr1zl'; //TODO Moze lepsza obsluga - na razie robi pole typu integer
FieldFormats.FORMAT_POSITIVE = 'positive'; //TODO Pelna obsluga
FieldFormats.FORMAT_ZUS = 'zus'; //TODO Pelna obsluga

FieldFormats.FORMAT_DATE = ['dd.mm.rrrr', 'dd-mm-rrrr', 'dd/mm/rrrr', 'mm/dd/rrrr', 'rrrrmmdd', 'ddmmrr', 'mm.rr', 'dd-mm',
    'mm-rrrr', 'mm/rrrr', 'mm.rrrr', 'rrrr-mm', 'rrrrmm', 'mmrrrr', 'ddmmrrrr', 'rrrr-mm-dd', 'rrrr.mm.dd', 'rrrr/mm/dd'];
FieldFormats.FORMAT_DATE_ZUS = 'datazus';

FieldFormats.FORMAT_PLEC_LISTA = 'plec_lista';
FieldFormats.FORMAT_PLEC_RADIO = 'plec_radio'; //TODO Pelna obsluga (NIP-7 obsługa aktówki?)
FieldFormats.FORMAT_RPODATNIKA_LISTA = 'rpodatnika_lista';
FieldFormats.FORMAT_2_CYFRY = '2_cyfry';
FieldFormats.FORMAT_MULTILINE = 'multiline';
FieldFormats.FORMAT_MULTILINE_FUNCTION = 'multiline_function'; //TODO Obsluga tego formatu jak będzie trzeba
FieldFormats.FORMAT_ZADRESEM_MULTI = 'zadresem_multi';
FieldFormats.FORMAT_WYPELNIJ_ZERAMI = 'wypelnij_zerami';

FieldFormats.FORMAT_KRAJ = 'kraj';
FieldFormats.FORMAT_KOD_KRAJU = 'kodkraju';
FieldFormats.FORMAT_KOD_NAZWA_KRAJU = 'kodnazwakraju'; //Tak samo jak kodkraju (ZUS US-1)
FieldFormats.FORMAT_ZADRESEM = 'zadresem';
FieldFormats.FORMAT_ZADRESEM_MULTI = 'zadresem_multi'; //TODO Obsluga multiline dla tego formatu
FieldFormats.FORMAT_BEZ_WALIDACJI = 'bez_walidacji';
FieldFormats.FORMAT_BEZ_ADRESU = 'bezadresu';
FieldFormats.FORMAT_BEZ_POGRUBIENIA = 'bez_pogrubienia';
FieldFormats.FORMAT_BEZ_PRZEKRESLENIA = 'bez_przekreslenia';
FieldFormats.FORMAT_POKAZ_WARTOSC = 'pokaz_wartosc';
FieldFormats.FORMAT_POKAZ_WARTOSC_EDEKL = 'pokaz_wartosc_edekl'; //TODO Do sprawdzenia czy potrzebne
FieldFormats.FORMAT_PRZEKRESLENIE_PUSTE = "przekreslenie_puste"; //TODO Obsluga jak bedzie trzeba (raczej tylko na wydruku)
FieldFormats.FORMAT_PRZEKRESLENIE = "przekreslenie"; //TODO Obsluga jak bedzie trzeba (raczej tylko na wydruku)
FieldFormats.FORMAT_DO_PRAWEJ = "do_prawej";
FieldFormats.FORMAT_ZERO = "zero"; //TODO Obsluga jak będzie trzeba (PIT/DS)
FieldFormats.FORMAT_ZERO_REQUIRED = "zero_required"; //TODO Obsluga jak będzie trzeba (GUS SP (2017))
FieldFormats.FORMAT_WSZYSTKIE_WIELKIE = "wszystkie_wielkie"; //TODO Obsluga jak będzie trzeba (GUS RF-01)
FieldFormats.FORMAT_PIERWSZA_WIELKA = "pierwsza_wielka"; //TODO Obsluga jak będzie trzeba (ZUS-RD-WRK-01)

FieldFormats.FORMAT_BUTTON_JS = "button_js"; //Pola buttonów, które są widoczne tylko w JS/Mobi


