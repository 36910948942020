import React from 'react';
import InputField from '../com/efile/ui/forms/InputField';
import TextAreaField from '../com/efile/ui/forms/TextAreaField';
import Icons from './ui/Icons';
import Form from '../com/efile/ui/forms/Form';
import CheckBoxField from '../com/efile/ui/forms/CheckBoxField';
import SiteConfig from './SiteConfig';
import Icon from '../com/efile/ui/Icon';
import BaseSiteComponent from './BaseSiteComponent';
import SiteRODO from "./SiteRODO";

//https://www.w3schools.com/react/react_forms.asp
export default class SiteKontakt extends BaseSiteComponent {

    constructor(props) {
        super(props);

        this.state.fetchURL = "/kontakt";
        this.state.componentId = "kontakt";
        this.state.data = {
            accept: false,
            name_surname : "",
            company : "",
            email : "",
            phone : "",
            body : ""
        };
        this.state.product = props.product ? JSON.parse(props.product) : null;

        //this.state.title = this.state.product ? 'Interesuje mnie produkt: ' + this.state.product.title : '';
        this.state.data.body = this.state.product
            ? 'Chciałbym uzyskać więcej informacji o produkcie: ' +
            this.state.product.title
            : '';
    }

    render() {
        let notify = this._getNotify();
        let className = "SiteKontakt contact-form form";
        if (this.state.loading) {
            className += " loading";
        }

        return <div ref="node" className={className}>
            <div className="header efileui-flex">
                <h3>{this.props.product
                    ? 'Dowiedz się więcej'
                    : 'Skontaktuj się'}</h3>
                {!this.props.full
                    ? <Icon embedSVG size={24} className="closeIcon"
                            onClick={this._onClose} icon={Icons.CLOSE}/>
                    : null}
            </div>
            {notify}
            {!this.state.completed ? <Form ref="form" data={this.state.data}
                                           onSubmit={this.onSubmit}>
                <InputField maxLength={100} required name="name_surname"
                            label="Imię i nazwisko"/>
                <InputField maxLength={100} name="company"
                            label="Nazwa firmy"/>
                <InputField maxLength={255} type="email" required name="email"
                            label="Adres e-mail"/>
                <InputField maxLength={16} required name="phone"
                            label="Telefon"/>
                <TextAreaField maxLength={512} required name="body"
                               label="Wiadomość"
                               rows={6}/>
                <CheckBoxField required id="form_accept"
                               label={SiteConfig.CONTACT_ACCEPT_TEXT}
                               name="accept"
                               requiredError={'Zaznaczenie tej opcji jest wymagane'}/>
                <SiteRODO text={SiteConfig.CONTACT_RODO_TEXT} />
                <div>

                </div>

                <div className="footer efileui-flex-right">
                    <button className="red" onClick={this.onSubmit}
                            disabled={this.state.loading}>Wyślij wiadomość
                    </button>
                </div>
            </Form> : null}
        </div>;
    }

    static defaultProps = {
        full: false, //full - na stronie kontaktu (bez opcji zamykania)
        product: null, //dane produktu w json
    };

}
