
export default class MathUtil {

    /**
     * Returns a random number between min (inclusive) and max (exclusive)
     */
    static getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive)
     * Using Math.round() will give you a non-uniform distribution!
     */
    static  getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * TODO Uzyc DataFormatter.formatNumber.padLeft
     * @param num
     * @returns {string}
     */
    static leadZero(num) {
        return safeParseInt(num) < 10 ? "0" + num : num;
    }

    static round(num, precision = 2)    
    {
        if (precision < 0) {
            return num;
        }
        if (!num || (num > -0.0000000001 && num < 0.0000000001)) {
            return 0;
        }
        if (precision == 0) {
            return Math.round(num);
        }
        var pow = Math.pow(10, precision);
        if (num > 0) {
            num += 0.0000000001;
        } else if (num < 0) {
            num -= 0.0000000001;
        }
        num = Math.round(num * pow) / pow;
        return num;
    }
}

MathUtil.MAX_PRECISION = 6;