import React from "react";
import InputField from "./InputField";

export default class TextAreaField extends InputField {

    constructor(props){
        super(props);

        this.htmlPropsList.push("rows");
    }

    onKeyDown(event) {
        //tu nie submitujemy
    }

    renderInput() {
        return  <textarea ref={(f) => this._setInputField(f)} {...this._getValidProps()} />
    }
}

TextAreaField.defaultProps = {
    ...InputField.defaultProps
};