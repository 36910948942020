export default class Icons {

}

Icons.NOTIFY_MESSAGE = "fa-info-circle";
Icons.NOTIFY_INFO = "fa-info-circle";
Icons.NOTIFY_ERROR = "fa-exclamation-triangle";
Icons.NOTIFY_WARNING = "fa-exclamation-triangle";

Icons.CLOSE = "fas fa-times";
Icons.PLUS = "fas fa-plus";
Icons.MINUS = "fas fa-minus";
Icons.SEARCH = "fas fa-search";

window.Icons = Icons;
