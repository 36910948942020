import React, {Fragment} from 'react';
import HSpacer from '../com/efile/ui/HSpacer';
import ArrayUtil from './util/ArrayUtil';
import DateUtil from './util/DateUtil';
import DataFormatter from './util/DataFormatter';
import Form from '../com/efile/ui/forms/Form';
import InputField from '../com/efile/ui/forms/InputField';
import TextAreaField from '../com/efile/ui/forms/TextAreaField';
import CheckBoxField from '../com/efile/ui/forms/CheckBoxField';
import SiteConfig from './SiteConfig';
import Icon from '../com/efile/ui/Icon';
import Icons from './ui/Icons';
import BaseSiteComponent from './BaseSiteComponent';
import SiteRODO from "./SiteRODO";
import BrowserUtil from "./util/BrowserUtil";

export default class SiteOrder extends BaseSiteComponent {

    constructor(props) {
        super(props);

        this.state.fetchURL = '/zamowienie/';
        this.state.fetchPriceURL = '/zamowienie/cena/';
        this.state.componentId = 'order';

        this.state.showDates = false;
        this.state.data = {
            clientType: 1,
            payMethod: 1,
            accept : false,
            accept_14_days : false,
            body : "",
        };
        this.state.step = props.full ? 2 : 1;

        if (localStorage.getItem('cart')) {
            this.state.cart = JSON.parse(localStorage.getItem('cart'));
        } else {
            this._clearCart();
        }
    }

    async componentDidMount() {
        if (this.props.offerId) {
            const product = await this._fetchOffer(this.props.offerId);
            this.setState({ product : product });
            this._addProduct(product);

            setTimeout(() => {
            const node = document.getElementById(this.state.componentId); //TODO Mozna z ref wziąc id jakby miało się zmieniać
            BrowserUtil.scrollToElement(node, true, false, document.body.parentNode);
        }, 500);
        }

        this._calculate();
    }

    _clearCart() {
        this.state.cart = {
            items: [],
            dateFrom: DataFormatter.toDatabaseDate(new Date()),
            dateTo: DataFormatter.toDatabaseDate(new Date()),
            priceNetto: 0,
            priceBrutto: 0,
            bailNetto: 0,
            bailBrutto: 0
        }; //TODO Najbliższa data robocza + 1
    }


    _getFetchBody() {
        return { data : this.state.data, cart : this.state.cart };
    }

    _fetchResult(message) {
        this._clearCart();
        this._saveCart();

        this.setState({completed: true, loading: false, message: message});
    }

    _addProduct(product) {
        for (let current of this.state.cart.items) {
            if (current.product.id === product.id) {
                if (current.quantity < product['available_quantity']) {
                    current.quantity++;
                }
                this._calculate();
                return;
            }
        }
        this.state.cart.items.push({product: product, quantity: 1});
        this._calculate();
        this._saveCart();
    }

    _deleteProduct(product) {
        for (let current of this.state.cart.items) {
            if (current.product.id === product.id) {
                ArrayUtil.removeItem(this.state.cart.items, current);
                this._calculate();
                this._saveCart();
                this.setState({});

                if (this.state.cart.items.length === 0) {
                    this._onClose();
                }
                return;
            }
        }
    }

    _updateProduct(product, quantity) {
        for (let current of this.state.cart.items) {
            if (current.product.id === product.id) {
                if (quantity >= 0 && quantity <=
                    product['available_quantity']) {
                    current.quantity = quantity;
                    this._calculate();
                    this._saveCart();
                    this.setState({});
                }
                return;
            }
        }
    }

    _saveCart() {
        localStorage.setItem('cart', JSON.stringify(this.state.cart));
    }

    onForward = (event) => {
        window.open('/zamowienie/', '_self');
    };

    onSubmitNext() {
        window.open('/oferta/', '_self');
    }

    changeFormHandler = (target, name, newValue) => {
        if (name === 'clientType' || name === 'address_invoice' || name === 'accept_14_days' || name === 'payMethod') {
            this.setState({});
        }
    };

    changeClientType = (event) => {
        this.state.data[event.target.name] = parseInt(event.target.value, 10);
        this.changeFormHandler(event.target, event.target.name,
            event.target.value);
    };

    changePayMethod = (event) => {
        this.state.data[event.target.name] = parseInt(event.target.value, 10);
        this.changeFormHandler(event.target, event.target.name,
            event.target.value);
    };

    changeDatesHandler = (event) => {
        this.state.cart[event.target.name] = event.target.value;

        this._calculate();
        this._saveCart();
        this.setState({});
    };

    _calculate = () => {
        let dateFrom = DataFormatter.fromDatabaseDate(this.state.cart.dateFrom);
        let dateTo = DataFormatter.fromDatabaseDate(this.state.cart.dateTo);
        let showDates = false;
        let numDays = DateUtil.getTimePeriod(dateFrom, dateTo);

        let data = {};
        data['num_days'] = numDays;
        data['date_from'] = this.state.cart.dateFrom;
        data['date_to'] = this.state.cart.dateTo;
        data['items'] = [];

        for (let item of this.state.cart.items) {
            if (item.product.category.type === 1) {
                showDates = true;
            }
            data['items'].push({ offer_id : item.product.id, quantity : item.quantity });
        }

        this.setState({ showDates : showDates });

        //przeliczamy cenę całkowitą i kaucję na serwerze
        this._calculateFetch(data);
    };

    async _calculateFetch(body) {
        const { ok, result, message } = await this._fetchData(JSON.stringify(body), this.state.fetchPriceURL);
        if (ok) {
            this.state.cart.priceNetto = result['price_netto'];
            this.state.cart.priceBrutto = result['price_brutto'];
            this.state.cart.bailNetto = result['bail_netto'];
            this.state.cart.bailBrutto = result['bail_brutto'];
            this.setState({});
        } else {
            this.setState({loading: false, error: String(message) });
        }
    }

    _getCartItems() {
        let cartItems = [];
        for (let cartItem of this.state.cart.items) {
            let product = cartItem.product;
            let quantity = parseInt(cartItem.quantity);
            let maxQuantity = parseInt(product['available_quantity']);
            let plusClassName = "plus";
            if (quantity === maxQuantity) {
                plusClassName += " disabled";
            }
            let minusClassName = "minus";
            if (quantity === 1) {
                minusClassName += " disabled";
            }
            let item = <div key={product.id} className="cart-item">
                <span className="title">{product.title}</span>
                <div className="quant">
                    <span>liczba sztuk</span>
                    <Icon className={minusClassName} embedSVG size={18}
                          onClick={this._updateProduct.bind(this, product,
                              quantity - 1)} icon={Icons.MINUS}/>
                    <h4>{quantity}</h4>
                    <Icon className={plusClassName} embedSVG size={18}
                          onClick={this._updateProduct.bind(this, product,
                              quantity + 1)} icon={Icons.PLUS}/>
                </div>
                <Icon embedSVG size={18}
                      className={"closeIcon"}
                      onClick={this._deleteProduct.bind(this, product)}
                      icon={Icons.CLOSE}/>
            </div>;
            cartItems.push(item);
        }
        return cartItems;
    }

    render() {
        let notify = this._getNotify();

        let priceNetto = DataFormatter.formatNumber(this.state.cart.priceNetto);
        let priceBrutto = DataFormatter.formatNumber(
            this.state.cart.priceBrutto);

        let bailNetto = DataFormatter.formatNumber(this.state.cart.bailNetto);
        let bailBrutto = DataFormatter.formatNumber(
            this.state.cart.bailBrutto);

        let className = "SiteOrder order-form form";
        if (this.state.loading) {
            className += " loading";
        }
        return <div ref="node" className={className}>
            <div className="header efileui-flex">
                <h3>Twoje zamówienie</h3>
                {!this.props.full
                    ? <Icon embedSVG size={24} className="closeIcon"
                            onClick={this._onClose} icon={Icons.CLOSE} />
                    : null}
            </div>
            {notify}
            {!this.state.completed ? this._getCartItems() : null}
            {!this.state.completed ? <>
                {this.state.showDates ? <div className="efileui-flex date-chooser">
                    <label className="dostawa-label">
                        <div>Początek wynajmu</div>
                        <span>(dzień dostawy)</span>
                    </label>
                    <input onChange={this.changeDatesHandler}
                           value={this.state.cart.dateFrom} required
                           name="dateFrom"
                           type="date" placeholder="Wynajem od"/>
                    <HSpacer width={30}/>
                    <label className="dostawa-label">
                        <div>Koniec wynajmu</div>
                        <span>(dzień zwrotu)</span>
                    </label>
                    <input onChange={this.changeDatesHandler}
                           value={this.state.cart.dateTo} required name="dateTo"
                           type="date" placeholder="Wynajem od"/>
                </div> : null }

                <div className="total-price">
                    {/*<div className="efileui-flex">
                        <span>Całkowity koszt zamówienia</span>
                        <h3>
                            <b>{priceBrutto} zł brutto</b>
                            &nbsp;
                            <span>({priceNetto} zł netto)</span>
                        </h3>
                    </div>*/}
                    <div className="total-price-cost">
                        Całkowity koszt wynajmu za deklarowany powyżej okres zostanie podany przez naszego pracownika w momencie potwierdzenia dostępności wybranych sprzętów.
                        Wysłanie zamówienia nie powoduje obowiązku zapłaty. Umowa najmu zostanie zawarta w momencie opłacenia zamówienia i kaucji.
                    </div>
                    {this.state.cart.bailNetto > 0 ?
                        <span className="description">
                            <span>Do ceny należy doliczyć kaucję zwrotną w kwocie </span>
                            <b>{bailBrutto} zł brutto</b>
                            &nbsp;
                            <span>({bailNetto} zł netto)</span>
                        </span> : null }
                </div>

                {this.state.step === 2 ?
                    <Form ref="form" data={this.state.data}
                          onChange={this.changeFormHandler}>
                        <hr/>
                        <h3>Dane wynajmującego</h3>

                        <div className="efileui-flex client-type">
                            <div>
                                <input id="clientPriv"
                                       onChange={this.changeClientType}
                                       checked={this.state.data['clientType'] ===
                                       1}
                                       name="clientType" type="radio"
                                       value="1"/>
                                <label htmlFor="clientPriv">Osoba
                                    prywatna</label>
                            </div>
                            <HSpacer width={50}/>
                            <div>
                                <input id="clientCompany"
                                       onChange={this.changeClientType}
                                       checked={this.state.data['clientType'] ===
                                       2}
                                       name="clientType" type="radio"
                                       value="2"/>
                                <label htmlFor="clientCompany">Firma</label>
                            </div>
                        </div>

                        <div className="efileui-flex">
                            <InputField maxLength={100} required
                                        name="name_surname"
                                        label="Imię i nazwisko"/>
                            <HSpacer width={50}/>
                            <InputField maxLength={10} required={this.state.data['clientType'] === 2} name="nip"
                                        label="NIP firmy"
                                        visible={this.state.data['clientType'] === 2}/>
                        </div>

                        <div className="efileui-flex">
                            <InputField maxLength={16} required name="phone"
                                        label="Telefon"/>
                            <HSpacer width={50}/>
                            <InputField maxLength={255} type="email" required
                                        name="email" label="Adres e-mail"/>
                        </div>

                        <h4 className="subheader">Adres dostawy</h4>

                        <div className="efileui-flex">
                            <InputField maxLength={255} required
                                        name="delivery_address_street" label="Ulica"/>
                            <HSpacer width={50}/>
                            <InputField maxLength={20} required
                                        name="delivery_address_number"
                                        label="Nr domu/lokalu"/>
                        </div>

                        <div className="efileui-flex">
                            <InputField maxLength={6} required
                                        name="delivery_address_code"
                                        label="Kod pocztowy"/>
                            <HSpacer width={50}/>
                            <InputField maxLength={100} required
                                        name="delivery_address_city"
                                        label="Miejscowość"/>
                        </div>

                        <br/>
                        <CheckBoxField id="address_invoice"
                                       label="Chcę podać inny adres do faktury"
                                       name="address_invoice"/>
                        {this.state.data['address_invoice'] ? <Fragment>
                            <h4 className="subheader">Adres do faktury</h4>

                            <div className="efileui-flex">
                                <InputField maxLength={255} required
                                            name="invoice_address_street"
                                            label="Ulica"/>
                                <HSpacer width={50}/>
                                <InputField maxLength={20} required
                                            name="invoice_address_number"
                                            label="Nr domu/lokalu"/>
                            </div>

                            <div className="efileui-flex">
                                <InputField maxLength={5} required
                                            name="invoice_address_code"
                                            label="Kod pocztowy"/>
                                <HSpacer width={50}/>
                                <InputField maxLength={100} required
                                            name="invoice_address_city"
                                            label="Miejscowość"/>
                            </div>

                        </Fragment> : null}

                        <h4 className="subheader">Preferowana forma płatności</h4>
                        <>
                            <div className="client-pay-method-subtitle">Po potwierdzeniu dostępności zamawianych sprzętów:</div>
                            <div className="client-pay-method">
                                <div>
                                    <input id="payMethod1" onChange={this.changePayMethod} checked={this.state.data['payMethod'] === 1}
                                           name="payMethod" type="radio" value="1"/>
                                    <label htmlFor="payMethod1">Zapłacę zaliczkę na czynsz przelewem + resztę dopłacę kartą/blik + kaucję dopłacę gotówką przy odbiorze</label>
                                </div>
                                <div>
                                    <input id="payMethod2" onChange={this.changePayMethod} checked={this.state.data['payMethod'] === 2}
                                           name="payMethod" type="radio" value="2"/>
                                    <label htmlFor="payMethod2">Zapłacę zaliczkę przelewem + resztę wraz z kaucją dopłacę kartą/blikiem przy odbiorze</label>
                                </div>
                                <div>
                                    <input id="payMethod3" onChange={this.changePayMethod} checked={this.state.data['payMethod'] === 3}
                                           name="payMethod" type="radio" value="3"/>
                                    <label htmlFor="payMethod3">Zapłacę całość z góry przelewem (czynsz + kaucja)</label>
                                </div>
                            </div>
                            {/*<div className="client-pay-method-description">
                                Wysłanie zamówienia nie powoduje obowiązku zapłaty wyliczonej kwoty.
                                Wpłata zaliczki lub całej kwoty wartości zamówienia (czynsz + kaucja) wymagana jest do zawarcia umowy najmu.
                                Możesz bezpłatnie odwołać złożoną rezerwację aż do momentu rozpoczęcia realizacji dostawy.
                            </div>*/}
                        </>

                        <h4 className="subheader">Uwagi</h4>
                        <TextAreaField maxLength={512} name="body" rows={6}/>
                        <br/>

                        <CheckBoxField required id="form_accept1"
                                       label={SiteConfig.ORDER_ACCEPT_TEXT_1}
                                       name="accept1"
                                       requiredError={'Zaznaczenie tej opcji jest wymagane'}/>
                        <CheckBoxField required id="form_accept2"
                                       label={SiteConfig.ORDER_ACCEPT_TEXT_2}
                                       name="accept2"
                                       requiredError={'Zaznaczenie tej opcji jest wymagane'}/>
                        <CheckBoxField id="form_accept3"
                                       label={SiteConfig.ORDER_ACCEPT_TEXT_3}
                                       name="accept_14_days" />
                        {!this.state.data.accept_14_days ? <div className={"accept14daysInfo"}>{SiteConfig.ORDER_ACCEPT_TEXT_4}</div> : null }

                        <SiteRODO text={SiteConfig.ORDER_RODO_TEXT} />

                        <hr/>
                        <span className="description">
                            Po wysłaniu formularza skontaktuje się z Państwem nasz pracownik w celu potwierdzenia dostępności urządzeń oraz ustalenia szczegółów realizacji zamówienia i darmowej dostawy. <b>Wysłanie zamówienia nie jest jednoznaczne z zawarciem umowy wynajmu.</b> Zawarcie umowy nastąpi dopiero w momencie opłacenia zamówienia.
                        </span>
                        <br/>

                    </Form> : null}

                <div className="footer efileui-flex-right">
                    {this.state.step === 1 ? <button className="gray"
                                                     onClick={this.onSubmitNext.bind(
                                                         this)}>Dodaj kolejny
                        produkt</button> : null}
                    {this.state.step === 2 ? <button className="red"
                                                     onClick={this.onSubmit}>Wyślij
                            zamówienie</button> :
                        <button className="red" onClick={this.onForward}>Przejdź
                            do
                            zamówienia</button>}
                </div>
            </> : null}
        </div>;
    }
}
