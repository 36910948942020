import React from "react";
import BaseField from "./BaseField";

export default class CheckBoxField extends BaseField {

    constructor(props){
        super(props);
        this.htmlPropsList.push("checked");
        this.state.value = false;
    }

    renderInput() {
        let p = this._getValidProps();
        p['checked'] = this.state.value;
        p['name'] = this.props.name;
        p['id'] = "checkbox_" + this.props.name;
        p['type'] = "checkbox";
        return  <input {...p} />
    }

    onChange = (event) => {
        this.clearValidation();

        const value = event.target.checked;
        if (this.context.onChange) {
            this.context.onChange(event.target, this.props.name, value);
        }

        this.setState( { value : value });
    };

    getDefaultValue() {
        return false;
    }

    renderLabel() {
        if (!this.props.label) {
            return null;
        }
        return  <label htmlFor={"checkbox_" + this.props.name} dangerouslySetInnerHTML={{ __html : this.props.label }} />
    }

    render() {
        if (!this.state.visible || !this.props.visible) {
            return null;
        }

        return  <div className={this.getClassName()}>
            <div className="inputContainer">
                {this.renderInput()}
                {this.props.required ? <span className="requiredIndicator" /> : null}
            </div>
            <div className="rightContainer">
                {this.renderLabel()}
                {this.renderErrors()}
            </div>
        </div>
    }
}

CheckBoxField.defaultProps = {
    ...BaseField.defaultProps
};