import React from "react";
import UIComponent from "../UIComponent";
import ArrayUtil from "../../../../www/util/ArrayUtil";
import FormContext from "./FormContext";

export default class Form extends UIComponent {

    constructor(props){
        super(props);

        this.customPropsList.push("onSubmit", "onChange", "onCommit", "data");

        this.fields = [];
        this.fieldsDict = {};
    }

    fieldChange = (target, name, newValue, commiting = false) => {
        //console.log("INTERNAL FIELD CHANGE", name, newValue, this.props.data);

        this.props.data[name] = newValue;

        if (this.props.onChange) {
            this.props.onChange(target, name, newValue);
        }
    };

    fieldCommit = (target, name, newValue) => {
        this.fieldChange(target, name, newValue, true);
    };

    /**
     * Callback odpalany przez pole po jego inicjalizacji (utworzeniu formData itp.).
     * @param target {BaseField}   Utworzony obiekt pola.
     */
    fieldInit = (target) => {
        //console.log("BaseForm.fieldInit (" + this.props.title + ")", target.props.name, target.fieldDef.getFieldInformation());

        this.fields.push(target);
        this.fieldsDict[target.props.name] = target;
    };

    /**
     * Callback odpalany przez pole po jego usunięciu.
     * @param target    Utworzony obiekt pola.
     */
    fieldRemoved = (target) => {
        //console.log("BaseForm.fieldRemoved (" + this.props.title + ")", target.props.name);

        delete this.fieldsDict[target.props.name];
        ArrayUtil.removeItem(this.fields, target);

    };

    fieldSubmit = (target) => {
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    };

    validate() {
        const fieldsDict = this.getFieldsDict();

        const errors = [];
        for (let p in fieldsDict) {
            let field = fieldsDict[p];
            field.clearValidation();
            let validationErrors = field.validate();
            if (validationErrors && validationErrors.length > 0) {
                field.updateValidation(validationErrors);
                errors.push({ name : field.props.name, errors : validationErrors });
            }
        }
        return errors;
    }

    getFields() {
        return this.fields;
    }

    getFieldsDict() {
        return this.fieldsDict;
    }

    getFieldByName(name) {
        const d = this.getFieldsDict();
        return d[name];
    }

    render() {
        const Context = FormContext.CONTEXT;
        //console.log("RENDER FORM", this);
        let className = "efileui-Form";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        let style = this.props.style ? this.props.style : {};

        const c = {
            data: this.props.data,
            onChange: this.fieldChange,
            onCommit: this.fieldCommit,
            onInit : this.fieldInit,
            onSubmit : this.fieldSubmit,
            onRemoved : this.fieldRemoved,
        };
        return  <Context.Provider value={c}>
            <form style={style} className={className}>
                {this.props.children}
            </form>
        </Context.Provider>
    }
}

Form.defaultProps = {
    ...UIComponent.defaultProps,
    data : null,
    onSubmit : null
};

Form.contextType = FormContext.CONTEXT;
