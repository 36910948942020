import MathUtil from "./MathUtil";

export default class ArrayUtil {

    static splitBy(arr, field) {
        let r = {};
        for (let prop in arr) {
            let item = arr[prop];
            let fieldValue = item[field];
            if (r[fieldValue] == undefined) {
                r[fieldValue] = [];
            }
            r[fieldValue].push(item);
        }
        return r;
    }

    static removeItem(arr, item) {
        //console.log("ArrayUtil.removeItem",arr, item);
        var itemIndex = arr.indexOf(item);
        if (itemIndex > -1) {
            arr.splice(itemIndex, 1);
        }
    }

    static cloneArray(arr) {
        //TODO moze tak A.splice(0,A.length)
        var r = [];
        for (var prop in arr) {
            r.push(arr[prop]);
        }
        return r;
    }

    static getUnique(ar) {
        //ES6: var unique = myArray.filter((v, i, a) => a.indexOf(v) === i);
        //ES2015 var uniqueItems = Array.from(new Set(items))
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        return ar.filter( onlyUnique );
    }

    static removeAll(arr) {
        arr.splice(0, arr.length);
    }

    static removeItemByIndex(arr, itemIndex) {
        //console.log("ArrayUtil.removeItem",arr, item);
        if (itemIndex > -1) {
            arr.splice(itemIndex, 1);
        }
    }

    static removeItemById(arr, itemId) {
        //console.log("ArrayUtil.removeItemById",arr, itemId);
        var item = ArrayUtil.getItemByParam(arr, "id", itemId);
        if (item) {
            return ArrayUtil.removeItem(arr, item);
        }
        return null;
    }

    static getItemByParam(arr, param, value) {
        for(var i = arr.length; i--;) {
            if(arr[i][param] == value) {
                return arr[i];
            }
        }
    }

    static getItemsByParam(arr, param, value) {
        var r = [];
        var len = arr.length;
        for(var i = 0; i < len; i++) {
            if(arr[i][param] == value) {
                r.push(arr[i]);
            }
        }
        return r;
    }

    static getItemById(arr, value) {
        //TODO Zrobic indeks po id i zwracac szybciej
        return ArrayUtil.getItemByParam(arr, "id", value);
    }

    static getItemIndex(arr, item) {
        return arr.indexOf(item);
    }

    static getRandomItem(arr)
    {
        var len = arr.length;
        var index = MathUtil.getRandomInt(0, len - 1);
        return arr[index];
    }

    static summary(arr, prop = null) {
        var s = 0;
        for (var p in arr) {
            var item = arr[p];
            var v = prop ? parseFloat(item[prop]) : parseFloat(item);
            if (!isNaN(v)) {
                s += v;
            }
        }
        return s;
    }

    static sortBy(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            if (a[property] === b[property]) {
                return 0;
            }
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    static sortBy2(property, property1) {
        var sortOrder = 1;
        var sortOrder1 = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        if(property1[0] === "-") {
            sortOrder1 = -1;
            property1 = property1.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            if (result == 0) {
                result = (a[property1] < b[property1]) ? -1 : (a[property1] > b[property1]) ? 1 : 0;
                sortOrder = sortOrder1;
            }
            return result * sortOrder;
        }
    }

    static randomItem(arr) {
        var randomIndex = Math.floor(Math.random() * arr.length);
        if (randomIndex >= arr.length - 1) {
            randomIndex = arr.length - 1;
        }
        return arr[randomIndex];
    }

    static extract(ar, param) {
        if (!ar) {
            return [];
        }
        var res = [];
        for (var prop in ar) {
            var ob = ar[prop];
            if (ob && ob[param]) {
                res.push(ob[param]);
            }
        }
        return res;
    }

    static extractParam(arr, param) {
        if (!arr) {
            return null;
        }
        var l = arr.length;
        var r = [];
        for(var i = 0; i < l; i++) {
            r.push(arr[i][param]);
        }
        return r;
    }

    static extractUnique(ar, param)
    {
        var res = [];
        for (var prop in ar)
        {
            var ob = ar[prop];
            if (res.indexOf(ob[param]) == -1)
            {
                res.push(ob[param]);
            }
        }
        return res;
    }
    
    static shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
    
    static getLastItem(ar) {
        return ar && ar.length > 0 ? ar[ar.length - 1] : null;
    }

    //Funkcja pomocnicza zamienia tablice na dict
    static toDict(array, key="id"){
        let dict = {};
        if (array) {
            for (var i = 0; i < array.length; i++){
                let obj = array[i];
                if (obj[key]) {
                    dict[obj[key]] = obj;
                }
            }
        }
        return dict;
    }

    static getValues(o) {
        var r = [];
        for (var prop in o) {
            r.push(o[prop]);
        }
        return r;
    }

    static setProperty(o, key, value) {
        for (let prop in o) {
            const item = o[prop];
            item[key] = value;
        }
    }
}

//JG Tak nie można bo psują się pętle for...in na tablicach - bo do propsów dodana jest ta funkcja
/*
Array.prototype.randomItem = function() {
    var randomIndex = Math.floor(Math.random() * (this.length - 1));
    return this[randomIndex];
};*/
