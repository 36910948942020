
export default class TweenUtil {

    /**
     * Animacja wartości z tweenem...
     * 
     * @param element   Obiekt, na którym animujemy
     * @param property  Wartośc do animowania (np. scrollTop)
     * @param to        Końcowa wartość parametru
     * @param duration  Czas trwania (w ms)
     */
    static animate(element, property, to, duration = 1000) {
        var start = element[property],
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function(){
            currentTime += increment;
            element[property] = TweenUtil.easeInOutQuad(currentTime, start, change, duration);
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

        //t = current time
        //b = start value
        //c = change in value
        //d = duration
    static easeInOutQuad(t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        }
}