import React from "react";
import UIComponent from "../UIComponent";
import FormContext from "./FormContext";

export default class BaseField extends UIComponent {

    constructor(props){
        super(props);

        this.htmlPropsList.push("id");
        this.customPropsList.push("onSubmit", "onChange", "onCommit", "name", "required", "label", "requiredError");
        this.state.errors = [];
    }

    componentDidMount() {
        if (this.context.onInit) {
            this.context.onInit(this);
        }
        if (this.context.data) {
            const value = this.context.data[this.props.name] !== undefined ? this.context.data[this.props.name] : this.getDefaultValue();
            this.setState( {value : value });
        }
    }

    getDefaultValue() {
        return "";
    }

    renderInput() {
        return null;
    }

    _getValidProps() {
        super._getValidProps();

        this.validProps["onChange"] = this.onChange;
        return this.validProps;
    }

    onChange = (event) => {
        this.clearValidation();

        if (this.context.onChange) {
            this.context.onChange(event.target, this.props.name, event.target.value);
        }

        this.state.value = event.target.value;
    };

    updateValidation(errors) {
        this.setState( { errors : errors });
    }

    clearValidation() {
        if (this.state.errors.length > 0) {
            this.setState( { errors : [] });
        }
    }

    renderLabel() {
        if (!this.props.label) {
            return null;
        }
        return  <label htmlFor={this.props.id}>
                    {this.props.label}
                    {this.props.required ? <span className="requiredIndicator" /> : null}
                </label>
    }

    renderErrors() {
        const hasErrors = this.state.errors.length > 0;
        return hasErrors ? <div className="errorContainer">{this.state.errors.join(", ")}</div> : null;
    }

    getClassName(baseName = null, prefix = null) {
        let className = super.getClassName("BaseField");
        if (this.state.errors.length > 0) {
            className += " error";
        }
        return className;
    }

    render() {
        if (!this.state.visible || !this.props.visible) {
            return null;
        }

        return  <div className={this.getClassName()}>
                    {this.renderLabel()}
                    <div className="rightContainer">
                        <div className="inputContainer">
                            {this.renderInput()}
                        </div>
                        {this.renderErrors()}
                    </div>
        </div>
    }

    validate() {
        const errors = [];
        if (this.props.required && !this.state.value) {
            errors.push(this.props.requiredError);
        }

        return errors;
    }

    dispose() {
        if (!this.disposed && this.context && this.context.onRemoved) {
            this.context.onRemoved(this);
        }

        super.dispose();
    }
}

BaseField.defaultProps = {
    ...UIComponent.defaultProps,
    focusable : true,
    required : false,
    label : null,
    requiredError : "Wypełnienie pola jest wymagane"
};

BaseField.contextType = FormContext.CONTEXT;