import React from "react";
import ReactDOM from "react-dom";
import SiteKontakt from "./www/SiteKontakt";
import SiteSearch from "./www/SiteSearch";
import SiteOrder from "./www/SiteOrder";
import SiteCartLink from "./www/SiteCartLink";

//ReactDOM.render(<UITestApp />, document.getElementById("app"));

window.siteOrder = function(containerId, offerId, full) {
    ReactDOM.render(<SiteOrder offerId={offerId} full={full} />, document.getElementById(containerId));
};

window.siteKontakt = function(containerId, offerId, full) {
     ReactDOM.render(<SiteKontakt offerId={offerId} full={full} />, document.getElementById(containerId));
};

window.siteSearch = function(containerId) {
    ReactDOM.render(<SiteSearch />, document.getElementById(containerId));
};

window.siteCartLink = function(containerId) {
    ReactDOM.render(<SiteCartLink />, document.getElementById(containerId));
};
