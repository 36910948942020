import React from "react";

export default class FormContext {

}

FormContext.CONTEXT = React.createContext({
    data : null, //obiekt z danymi formularza
    onChange : null, //funckcja zmiany pola
    onCommit : null, //funkcja zatwierdzenie pola
    onInit : null, //funkcja inicjalizacji pola
    onSubmit : null, //funkcja odpalana na enter w polu tekstowym (nie textarea)
    onRemoved : null, //funkcja inicjalizacji pola
});


