import React from 'react';
import SiteConfig from "./SiteConfig";

export default class SiteRODO extends React.Component {

    constructor(props) {
        super(props); // {text : string}

        this.state = { expanded : false };
    }

    _onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ expanded : true});
    };

    render() {
        if (!this.state.expanded) {
            return <span className={"SiteRODO link"}><a href="#" onClick={this._onClick} title={SiteConfig.RODO_HEADER}>{SiteConfig.RODO_HEADER}</a></span>;
        }

        return <span dangerouslySetInnerHTML={{ __html : this.props.text}} className={"SiteRODO text"} />;
    }
}
