import ArrayUtil from "./ArrayUtil";
export default class StringUtil {

    /**
     * Ucina tekst, jeśli jest dłuższy niż podana maksymalna liczba znaków.
     *
     * @param    str                Tekst do ucięcia.
     * @param    maxChars        Maksymalna liczba znaków.
     * @param    cutIndicator    Znacznik po ucięciu, standardowo '...'.
     * @return
     */
    static cutChars(str, maxChars = 100, cutIndicator = "...") {
        if (!str) {
            return "";
        }
        return str.length < maxChars ? str : str.substr(0, maxChars) + cutIndicator;
    }
    
    static countChars(str, char) {
        return str.split(char).length - 1;
    }
    
    static clearDiactricts(input) {
        if(!input) {
            return "";
        }
        var patterns = ['ą', 'Ą', 'ć', 'Ć', 'ę', 'Ę', 'ł', 'Ł', 'ń', 'Ń', 'ó', 'Ó', 'ś', 'Ś', 'ż', 'Ż', 'ź', 'Ź'];
        var replace = ['a', 'A', 'c', 'C', 'e', 'E', 'l', 'L', 'n', 'N', 'o', 'O', 's', 'S', 'z', 'Z', 'z', 'Z' ];
        var l = patterns.length;
        for (var i = 0; i < l; i++) {
            var pattern = patterns[i];
            if (input.indexOf(pattern) > -1) {
                input = input.split(pattern).join(replace[i]);
            }
        }
        return input;
    }

    //TODO Zrobić natywnym String.trim + polyfill
    static trim(str, addChars = null) {
        if (str === null || str === undefined) {
            return '';
        }
        else {
            let chars = [' ', '\r', '\n', '\t'];
            if (addChars) {
                chars = chars.concat(addChars);
            }
            
            str = str.toString();

            while (chars.indexOf(str.substr(0, 1)) > -1) {
                str = str.substr(1);
            }

            while (chars.indexOf(str.substr(str.length - 1, 1)) > -1) {
                str = str.substr(0, str.length - 1);
            }
            return str;
        }
    }

    static toLowerFirst(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    static toUpperFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static clearSpaces(string) {
        return string.replace(/ /g, '-');
    }

    static clearCommas(string) {
        return string.replace(/,/g, '-');
    }

    static replace(input, toReplace, replaceWith) {
        return input ? input.split(toReplace).join(replaceWith) : '';
    }

    static leadZero(n, digits = 2) {
        var ret = n.toString();
        while (ret.length < digits) {
            ret = '0' + ret;
        }
        return ret;
    }

    static trimSpaceDash(ret) {
        ret = StringUtil.replace(ret, '-', '');
        return StringUtil.replace(ret, ' ', '');
    }

    static trimSlashCollon(ret) {
        ret = StringUtil.replace(ret, '/', '');
        return StringUtil.replace(ret, ',', '');
    }

    static getSafeFileName(fileName, trimSpaces = false, trimBrackets = false) {
        fileName = StringUtil.clearDiactricts(fileName);
        var ret = fileName.replace(/[^a-zA-Z0-9-\.\_()\[\]\s]+/g, '_');
        ret = StringUtil.replace(ret, '\n', '');
        ret = StringUtil.replace(ret, '\r', '');
        ret = StringUtil.replace(ret, '\t', '');
        ret = StringUtil.replace(ret, '/', ' ');
        if (trimSpaces) {
            ret = StringUtil.replace(ret, ' ', '_');
        }
        if (trimBrackets) {
            ret = StringUtil.replace(ret, '(', '');
            ret = StringUtil.replace(ret, ')', '');
        }
        return ret ? ret : "plik";
    }

    //AM prawidłowe kodowanie znaku "/"
    static encodeURIComponent(component) {
        return encodeURIComponent(StringUtil.replace(StringUtil.replace(component, '/', '%252F'), '%2F', '%252F'));
    }
    
    static randomPassword(length, specialNum = 1, upperNum = 1, numberNum = 1) {
        var lowerCase     = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', /*'l', */'m', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        var numbers       = ['1', '2', '3', '4', '5', '6', '7', '8', '9'/*, '0'*/];
        var upperCase     = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', /*'I', */'J', 'K', 'L', /*'O', */'P', 'R', 'S', 'Z'];
        var specChars     = ['!', '@', '#', '$', '%', '^', '&', '*'];

        var result = "";

        if(specialNum > 0) {
            result += StringUtil.getRandChars(specChars, specialNum);
        }
        if(upperNum > 0) {
            result += StringUtil.getRandChars(upperCase, upperNum);
        }
        if(numberNum > 0) {
            result += StringUtil.getRandChars(numbers, numberNum);
        }
        var rest = length - (specialNum + numberNum + upperNum);
        if(rest > 0) {
            result += StringUtil.getRandChars(lowerCase, rest);
        }

        var tmpAr = result.split("");
        tmpAr = ArrayUtil.shuffle(tmpAr);
        result = tmpAr.join("");

        return result;
    }

    static getRandChars(chars, specialNum) {
        var result = "";
        for (var i = 0; i < specialNum; i++) {
            result += chars[Math.floor(Math.random() * chars.length)]
        }
        return result;
    }

    static validateGiodoPassword(pass) {
        var rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-=_+\[\]\{};':"\\,\.<>\/?])[A-Za-z\d!@#$%^&*()-=_+\[\]\{};':"\\,\.<>\/?]{8,}$/;
        return rgx.test(pass);
    }

    /**
     * Przeniesione z AS3
     */
    static formatAddress(ul, nrDomu, nrLokalu, miejscowosc, poczta = null, kodPocztowy = null) {
        var result = "";

        if (ValidationUtil.isText(miejscowosc) && (miejscowosc != poczta)) { //jeśli jest miejscowość...
            if (ValidationUtil.isText(ul)) {
                result += "{miejsc},";
            } else {
                result += "{miejsc}";
            }
        }

        if (result != "") {
            result += " ";
        }

        if (ValidationUtil.isText(ul)) {
            result += "{ul} {nrDomu}";
        } else {
            result += "{nrDomu}";
        }

        if (ValidationUtil.isText(nrLokalu)) {
            result += "/{nrLokalu}";
        }

        if (kodPocztowy) {
            result += ", {kod} {poczta}";
        }

        result = StringUtil.replace(result, "{miejsc}", ValidationUtil.isText(miejscowosc) ? miejscowosc : "");
        result = StringUtil.replace(result, "{ul}", ValidationUtil.isText(ul) ? ul : "");
        result = StringUtil.replace(result, "{nrDomu}", ValidationUtil.isText(nrDomu) ? nrDomu : "");
        result = StringUtil.replace(result, "{nrLokalu}", ValidationUtil.isText(nrLokalu) ? nrLokalu : "");
        result = StringUtil.replace(result, "{kod}", ValidationUtil.isText(kodPocztowy) ? kodPocztowy : "");
        result = StringUtil.replace(result, "{poczta}", ValidationUtil.isText(poczta) ? poczta : "");

        result = StringUtil.trim(result);
        return result;
    }

    /**
     * @param input {String}
     * @param args {Array}
     * @returns {String}
     */
    static replaceArgs(input, args ) {
        for (var i = 0; i < args.length; i++) {
            input = input.replace("{" + i + "}", args[i]);
        }
        return input;
    }
}