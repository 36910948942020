import TweenUtil from "./TweenUtil";
import StringUtil from "./StringUtil";
import Cookies from "js-cookie";
import Bowser from "bowser";
import URLUtil from "./URLUtil";

export default class BrowserUtil {

    static getCurrentHash() {
        let hash = window.location.hash;
        return StringUtil.replace(hash, "#/", "");
    }

    static formatRoute(route, toLowerCase = false) {
        route = StringUtil.clearDiactricts(route);
        route = StringUtil.clearSpaces(route);
        route = StringUtil.clearCommas(route);
        if (toLowerCase) {
            route = route.toLowerCase();
        }
        return route;
    }

    static scrollBottom(itemId) {
        var c = document.getElementById(itemId);
        c.scrollTop = c.scrollHeight - c.clientHeight;
    }

    static scrollToElement(elementNode, animate = true, top = false, offsetParent = null) {
        if (!offsetParent) {
            offsetParent = elementNode.offsetParent;
        }
        if (offsetParent) {
            var v = top ? 0 : elementNode.offsetTop - 5;
            if (animate) {
                var time = Math.abs((elementNode.offsetTop - offsetParent.scrollTop)) / 2;
                TweenUtil.animate(offsetParent, "scrollTop", v, time);
            }
            else {
                offsetParent.scrollTop = v;
            }
        }
    }

    /**
     * http://stackoverflow.com/questions/3588628/can-i-change-the-viewport-meta-tag-in-mobile-safari-on-the-fly
     * @param v
     */
    static setScalable(v = true) {
        //TODO
    }

    static screenOrientation() {
        return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    }

    //https://stackoverflow.com/questions/5188908/detecting-installed-plugins-under-different-browsers
    static getBrowserPlugins() {
        var pLen = navigator.plugins.length; // store the total no of plugin stored
        var p = [];
        for(var i=0;i<pLen;i++) {
            p.push(navigator.plugins[i].name + "" + (navigator.plugins[i].version ? " " + navigator.plugins[i].version : ""));
        }
        return p;
    }

    /**
     * Pobiera parametry GET (np. ?abc=1) oraz parametry po # (#cde=2).
     * //#UNITTEST
     */
    static getQueryParams(getParams = false, hashParams = true, customHash = null) {

        let result = {};

        if (hashParams) {
            let params = {};
            let hash = customHash ? customHash : window.location.hash.substring(1);
            let hParams = "";
            if (hash && hash.indexOf("?") > -1) {
                hParams = hash.split('?');
                hParams = hParams[1];
            } else {
                hParams = hash;
            }

            if (hParams && hParams.length > 0) {
                let p = hParams.indexOf("&") > -1 ? hParams.split('&') : [hParams];
                p.map(hk => {
                    let temp = hk.split('=');
                    if (temp[0]) {
                        params[temp[0]] = temp[1];
                    }
                });
            }
            result = params;
        }

        if (getParams) {
            result = Object.assign(result, BrowserUtil.getQueryString());
        }

        return result;
    }

    static getQueryString() {
        //TODO JG Wywalic querystring.js

        // This function is anonymous, is executed immediately and
        // the return value is assigned to QueryString!
        var query_string = {};
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }
        return query_string;
    }

    static formatQueryParams(params, encodeURIComponents = true) {
        return URLUtil.formatQueryParams(params, encodeURIComponents);
    }

    static init() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserProps = Bowser.parse(window.navigator.userAgent);
        BrowserUtil.BROWSER_NAME = browser.getBrowserName();
        BrowserUtil.BROWSER_PROPS = browserProps;
        BrowserUtil.BROWSER_CHROME = browser.isBrowser("Chrome");
        BrowserUtil.BROWSER_FIREFOX = browser.isBrowser("Firefox");
        BrowserUtil.BROWSER_IE = browser.isBrowser("Internet Explorer");
        BrowserUtil.BROWSER_EDGE = browser.isBrowser("Microsoft Edge");
        BrowserUtil.BROWSER_EDGE_CHROMIUM = browserProps && browserProps.engine && browserProps.engine['name'] === 'Blink'; //JG: Wersja Edge na silniku Chromium (od Edge 79)
        BrowserUtil.BROWSER_OPERA = browser.isBrowser("Opera");
    }

    /**
     * Sprawdzenie czy jest to przeglądarka IE lub Edge starego typu.
     *
     * @returns {*}
     */
    static isEdgeOrIE() {
        if (BrowserUtil.BROWSER_EDGE_CHROMIUM) {
            return false;
        }
        return BrowserUtil.BROWSER_IE || BrowserUtil.BROWSER_EDGE;
    }

    static copyToClipboard(text) {
        //TODO lepiej użyć clipboard-js lub podobnego
        //TODO jakieś małe powiadomienie że skopiowano do schowka
        try {
            navigator.clipboard.writeText(text);
        }
        catch (er) {
            console.error("BrowserUtil.copyToClipboard", text, er);
        }
    }

    //JG: https://rudrastyh.com/javascript/favorite-button.html
    static addToFavorites(message = null, pageURL = null) {
        const m = 'Wciśnij ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D aby dodać ' + (message ? message : 'tę stronę do ulubionych.');
        const pageTitle = document.title;
        if (!pageURL) {
            pageURL = document.location;
        }

        try {
            // Internet Explorer solution
            eval("window.external.AddFa-vorite(pageURL, pageTitle)".replace(/-/g,''));
        }
        catch (e) {
            try {
                // Mozilla Firefox solution
                window.sidebar.addPanel(pageTitle, pageURL, "");
            }
            catch (e) {
                // Opera solution
                if (typeof(opera)=="object") {
                    a.rel="sidebar";
                    a.title=pageTitle;
                    a.url=pageURL;
                    return true;
                } else {
                    // The rest browsers (i.e Chrome, Safari)
                    Dialogs.alert(m);
                    //alert(m);
                }
            }
        }
        return false;
    }

    /**
     * Pobiera domenę na podstawie URLa. Domyślnie wycina subdomeny.
     *
     * https://stackoverflow.com/questions/11401897/get-the-current-domain-name-with-javascript-not-the-path-etc
     *
     * @param url
     * @param subdomain
     * @returns {*}
     */
    //#UNITTEST: test.e-pity.mobi, www.test.e-pity.mobi, http://test.e-pity.mobi, https://test.e-pity.mobi > ma zwrócić zawsze 'e-pity.mobi'
    static getDomain(url, subdomain) {
        subdomain = subdomain || false;

        url = url.replace(/(https?:\/\/)?(www.)?/i, '');

        if (!subdomain) {
            url = url.split('.');

            url = url.slice(url.length - 2).join('.');
        }

        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }

        return url;
    }

    /**
     * Usuwanie cookie. UWAGA - ciasteczko zostanie poprawnie usunięte jeśli podamy zgodne name, path i domain.
     *
     * https://github.com/js-cookie/js-cookie#basic-usage
     *
     * @param name
     * @param path
     * @param domain
     */
    static removeCookie(name, path = undefined, domain = undefined) {
        let o = {};
        if (path !== undefined) {
            o['path'] = path;
        }
        if (domain !== undefined) {
            o['domain'] = domain;
        }
        return Cookies.remove(name, o);
    }

    static getCookie(name) {
        return Cookies.get(name);
    }

    static setCookie(name, value, params = null) {
        return Cookies.set(name, value, params);
    }
}

