import React from 'react';
import Icons from './ui/Icons';
import SiteConfig from './SiteConfig';
import BrowserUtil from './util/BrowserUtil';
import ReactDOM from 'react-dom';
import InlineNotifyItem from "./ui/InlineNotifyItem";

export default class BaseSiteComponent extends React.Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            errors: [],
            message : null,
            completed : false,
            fetchURL : "",
            componentId : "" };

        this.onSubmit = this.onSubmit.bind(this);

        Icons;
    }

    _onClose = () => {
        //na stronie oferty usuwamy komponent
        if (this.props.offerId) {
            const node = document.getElementById(this.state.componentId); //TODO Mozna z ref wziąc id jakby miało się zmieniać
            ReactDOM.unmountComponentAtNode(node);
        } else {
            //na stronie koszyka wracamy do oferty
            window.open("/oferta/", "_self");
        }
    };

    async onSubmit(e) {
        e.preventDefault();

        //await AsyncUtil.asyncSetTimeout(100);

        const form = this.refs.form;
        const errors = form.validate();
        if (errors.length > 0) {
            this.setState(
                {error: 'Popraw błędnie wypełnione pola', errors: errors});
            return;
        }

        this.setState({loading : true});
        grecaptcha.ready(() => {
            grecaptcha.execute(SiteConfig.RECAPTCHA_KEY, {action: 'submit'}).
                then((token) => {
                    this._runFetch(token);
                });
        });
    }

    async _runFetch(token) {
        this.setState({loading: true});
        const body = this._getFetchBody();
        body['recaptcha'] = token;

        //TODO Analytics
        const {ok, message} = await this._fetchData(JSON.stringify(body));
        if (ok) {
            this._fetchResult(message);
        } else {
            this.setState({loading: false, error: message});
        }
    }

    _fetchResult(message) {
        this.setState({completed: true, loading: false, message: message});
    }

    _getFetchBody() {
        return this.state.data;
    }

    async _fetchData(body, fetchURL = null) {
        try {
            const url = fetchURL ? fetchURL : this.state.fetchURL;
            const response = await fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': BrowserUtil.getCookie('csrftoken'),
                    },
                    mode: 'same-origin',
                    method: 'POST',
                    body: body,
                });

            const t = await response.text();
            //console.log('TEXT', t);
            const json = JSON.parse(t);
            json['status'] = response.status;
            json['body'] = response.body;
            json['ok'] = response.ok;
            return json;
        }
        catch (er) {
            return {ok: false, message: 'Nieznany błąd: ' + er.message};
        }
    }

    async _fetchOffer(offerId) {
        try {
            const response = await fetch(SiteConfig.API_PRODUCTS_OFFERS + offerId + "/",
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': BrowserUtil.getCookie('csrftoken'),
                    },
                    mode: 'same-origin',
                    method: 'GET',
                });

            const t = await response.text();
            const json = JSON.parse(t);
            console.log('JSON', json);
            return json;
        }
        catch (er) {
            this.setState({loading: false, error: 'Nieznany błąd wczytywania oferty: ' + er.message});
        }
    }

    _getNotify() {
        let notify = null;
        if (this.state.error) {
            notify = <InlineNotifyItem type="error"
                                       content={this.state.error}/>;
        }
        if (this.state.completed) {
            notify =
                <InlineNotifyItem type="message" content={this.state.message}/>;
        }
        return notify;
    }
}
