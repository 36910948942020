
export default class UIDUtil {

    static init() {
        var lut = [];
        for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
        UIDUtil.lut = lut;
    }

    /**
     * Generowanie UID dla zapisywanych do bazy obiektów (DBObject itp.).
     *
     * @returns {string}
     */
    static createUID2() {
        if (UIDUtil.IS_TESTING) {
            return "1234567890";
        }

        return "id" + UIDUtil.createUID(false);
    }

    /**
     * Algorytm przeniesiony z AS3.
     *
     * Złożoność 26 losowych znaków z puli 16 znaków + 8 znaków timestamp.
     */
    static createUID(addDashes = true) {
        let uid = addDashes ? new Array(36) : new Array(32);
        let index = 0;

        for (let i = 0; i < 8; i++) {
            uid[index++] = UIDUtil.ALPHA_CHAR_CODES[Math.floor(Math.random() * 16)];
        }

        for (let i = 0; i < 3; i++) {
            if (addDashes) {
                uid[index++] = 45; // charCode for "-"
            }

            for (let j = 0; j < 4; j++) {
                uid[index++] = UIDUtil.ALPHA_CHAR_CODES[Math.floor(Math.random() * 16)];
            }
        }

        if (addDashes) {
            uid[index++] = 45; // charCode for "-"
        }

        let time = new Date().getTime();

        let timeString = ("0000000" + time.toString(16).toUpperCase()).substr(-8);

        for (let i = 0; i < 8; i++) {
            uid[index++] = timeString.charCodeAt(i);
        }

        for (let i = 0; i < 4; i++) {
            uid[index++] = UIDUtil.ALPHA_CHAR_CODES[Math.floor(Math.random() * 16)];
        }

        return String.fromCharCode.apply(null, uid);
    }

    static createCustomUID(length = 36) {
        let time = new Date().getTime();
        let timeString = ("0000000" + time.toString(16).toUpperCase()).substr(-8);
        let charCodesLength = UIDUtil.LONG_ALPHA_CHAR_CODES.length-1;
        let uid = [];

        for (let i = 0; i < (length-8); i++) {  //odejmuję 8, bo po drodze wstawiam 8 znaków czasu
            uid.push(UIDUtil.LONG_ALPHA_CHAR_CODES[Math.floor(Math.random() * charCodesLength)]);

            if(i === 8) {
                for (let j = 0; j < 8; j++) {
                    uid.push(timeString.charCodeAt(j));
                }
            }
        }

        return String.fromCharCode.apply(null, uid);
    }

    /**
     * Uproszczony algorytm generowania UID używany poprzednio w JS.
     *
     * http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
     * http://jsfiddle.net/jcward/7hyaC/3/
     *
     * @returns {string}
     */
    static createUIDSimple()
    {
        if (UIDUtil.IS_TESTING) {
            return "1234567890";
        }

        if (!UIDUtil.lut) {
            UIDUtil.init();
        }
        var lut = UIDUtil.lut;

        var d0 = Math.random()*0xffffffff|0;
        var d1 = Math.random()*0xffffffff|0;
        var d2 = Math.random()*0xffffffff|0;
        var d3 = Math.random()*0xffffffff|0;
        return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
            lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
            lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
            lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
    }
}

UIDUtil.IS_TESTING = false;
UIDUtil.ALPHA_CHAR_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70];
UIDUtil.LONG_ALPHA_CHAR_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90];