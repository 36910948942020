import React from "react";
import ViewComponent from "../epity/mobile/view/ViewComponent";

export default class UIComponent extends ViewComponent {

    constructor(props){
        super(props);

        this.state = { hovered : false, focused : false, visible : true };
        this.htmlPropsList = ["children", "className", "onClick", "onDoubleClick", "onChange", "style", "disabled", "title", "onMouseLeave", "onMouseEnter", "onMouseOver", "onMouseOut", "onContextMenu", "onDragOver", "onDrop", "onDragEnd", "onDragLeave", "onDragStart", "draggable"]; //propsy przekazywane do komponentu html
        this.customPropsList = ["uid", "tappable", "classPrefix", "modifier", "hoverable", "disabled", "enabled", "tooltip", "focusable", "onFocus", "onBlur", "visible"]; //dodatkowe propsy obsługiwane przez komponent //TODO Docelowo można pewnie wywalić i walidowac za pomocą React.PropTypes
        this.validProps = {};

        this._onOut = this._onOut.bind(this);
        this._onOver = this._onOver.bind(this);
        this.onFocusIn = this.onFocusIn.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
    }

    _getValidProps() {
        const p = this.props;
        this.validProps = {};
        for (let prop in p) {
            if (p.hasOwnProperty(prop)) {
                if (this.htmlPropsList.indexOf(prop) > -1) {
                    this.validProps[prop] = p[prop];
                }
                else {
                    if (this.customPropsList.indexOf(prop) === -1) {
                        console.warn(this.getClassName(), "_updateProps", "unsupported prop:", prop);
                    }
                }
            }
        }

        if (p.hoverable) {
            this.validProps["onMouseEnter"] = this._onOver;
            this.validProps["onMouseLeave"] = this._onOut;
        }

        if (p.focusable) {
            this.validProps["onFocus"] = this.onFocusIn;
            this.validProps["onBlur"] = this.onFocusOut;
        }

        this.validProps['className'] = this.getClassName();

        if (p.tooltip) {
            //TODO JG: Zrobić faktyczne pokazywanie tooltipów po najechaniu
            this.validProps['title'] = this.validProps['alt'] = p.tooltip;
        }

        return this.validProps;
    }

    _onOver(event) {
        if (this.props.hoverable) {
            this.setState({hovered : true});
            if (this.props.onMouseEnter) {
                this.props.onMouseEnter(event);
            }
        }
    }

    _onOut(event) {
        if (this.props.hoverable) {
            this.setState({hovered : false});
            if (this.props.onMouseLeave) {
                this.props.onMouseLeave(event);
            }
        }
    }

    onFocusIn(event) {
        if (this.props.focusable) {
            this.setState({focused : true});
            if (this.props.onFocus) {
                this.props.onFocus(event);
            }
        }
    }

    onFocusOut(event) {
        if (this.props.focusable) {
            this.setState({focused : false});
            if (this.props.onBlur) {
                this.props.onBlur(event);
            }
        }
    }

    getClassName(baseName = null, prefix = null) {
        let c = super.getClassName(baseName, "efileui-");

        c += " UIComponent";

        if (this.props.modifier) {
            c += " " + this.props.modifier;
        }
        if (this.props.hoverable) {
            c += " hoverable";
        }

        if (this.state) {
            if (this.props.hoverable && this.state.hovered) {
                c += " hovered";
            }
            if (this.state.focused) {
                c += " focused";
            }
            if (this.props.disabled) {
                c += " disabled";
            }
        }
        return c;
    }

    render() {
        if (!this.state.visible || !this.props.visible) {
            return null;
        }

        return  <div {...this._getValidProps()}>
                    {this.props.children}
                </div>
    }
}

UIComponent.defaultProps = {
    tappable : false, //TODO Obsluga
    hoverable : false,
    focusable : false,
    modifier : "",
    tooltip : null, //TODO tooltip
    uid : null,
    onFocus : null,
    onBlur : null,
    className : null,
    visible : true
};