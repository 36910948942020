import ClassUtil from "./ClassUtil";
import DataFormatter from "./DataFormatter";
const equal = require('deep-equal');

export default class ObjectUtil {

    static getClassName(obj) {
        return ClassUtil.getClassName(obj);
    }

    static summary(obj, ...props) {
        if (obj == null || obj == undefined) {
            return 0;
        }
        var s = 0;
        for (var p in props) {
            var prop = props[p];
            var v = parseFloat(obj[prop]);
            if (!isNaN(v)) {
                s += v;
            }
        }
        return s;
    }

    static summary2(...values) {
        return ObjectUtil.summary3(values);
    }

    static summary3(values) {
        var s = 0;
        for (var p in values) {
            var v = parseFloat(values[p]);
            if (!isNaN(v)) {
                s += v;
            }
        }
        return s;
    }

    static getKeys(o) {
        var k = [];
        for (var p in o) {
            k.push(p);
        }
        return k;
    }

    static shallowEqual(a, b) {
        for (let prop in a) {
            if (a[prop] !== b[prop]) {
                return false;
            }
        }
        return true;
    }

    static deepEqual(a, b, opts = null) {
        return equal(a, b, opts);
    }

    static shallowEqualChanges(a, b) {
        const changes = [];
        for (let prop in a) {
            if (a[prop] !== b[prop]) {
                changes.push(prop);
            }
        }
        return changes;
    }
    
    static assignFields(target, from, fields) {
        for (var prop in fields) {
            var field = fields[prop];
            target[field] = from[field];
        }
    }

    static assign(target, source, targetSuffix = "", omitPrivate = false) {
        for (var prop in source) {
            if (omitPrivate && prop && prop.indexOf("_") == 0) {
                continue;
            }
            target[prop + targetSuffix] = source[prop];
        }
        return target;
    }

    static parseSimpleType(v, type) {
        switch (type) {
            case "int":
                return safeParseInt(v);
            case "Number":
                return parseFloat(v);
            case "Date":
                let parsedDate = DataFormatter.parseStringDate(v);
                if (parsedDate) {
                    return parsedDate;
                }
                return Number(v) != 0 ? new Date(Number(v)) : null;
            case "DateUTC":
                //JG: tworzymy lokalną datę na podstawie daty UTC
                if (v === null || v === "" || v === undefined) {
                    return null;
                }
                const timestamp = !isNaN(Number(v)) ? Number(v) : 0;
                const dateUTC = new Date(timestamp + 2*60*60*1000); //JG: Dodajemy 2h jakby to była data w Warszawie (z e-pity desktop)
                return dateUTC.localDateFromUTC(0, 0, 0);
            case "Boolean":
                return typeof(v) == "boolean" ? v : (v == "1" || v == "true");
            case "String":
                return String(v);
        }
        return v;
    }
    
    static parseBoolean(v) {
        return ObjectUtil.parseSimpleType(v, "Boolean");
    }

    /**
     *  Compares two String values.
     *
     *  @param a First String value.
     *
     *  @param b Second String value.
     *
     *  @param caseInsensitive Specifies to perform a case insensitive compare,
     *  <code>true</code>, or not, <code>false</code>.
     *
     *  @return 0 is both Strings are null.
     *  1 if only <code>a</code> is null.
     *  -1 if only <code>b</code> is null.
     *  -1 if <code>a</code> precedes <code>b</code>.
     *  1 if <code>b</code> precedes <code>a</code>.
     */
    static stringCompare(a, b, caseInsensitive = false) {
        if (a == null && b == null)
            return 0;

        if (a == null)
            return 1;

        if (b == null)
            return -1;

        // Convert to lowercase if we are case insensitive.
        if (caseInsensitive) {
            a = a.toLocaleLowerCase();
            b = b.toLocaleLowerCase();
        }

        var result = a.localeCompare(b);

        if (result < -1)
            result = -1;
        else if (result > 1)
            result = 1;

        return result;
    }

    static clone(o) {
        return JSON.parse(JSON.stringify(o));
    }

    /**
     * Porównanie dwóch obiektów.
     *
     * @param ob1   Obiekt porównywany 1
     * @param ob2   Obiekt porównywany 2
     * @param deep  Jeśli true - porównuje pełne obiekty, jeśli nie - tylko pierwsze zagłębienie.
     * @returns {*}
     */
    static compare(ob1, ob2, deep = false) {
        if (!deep) {
            const changes = ObjectUtil.shallowEqualChanges(ob1, ob2);
            if (changes.length > 0) {
                //console.log("ObjectUtil.compare", "not equal props:", changes);
                return false;
            }
            return true;
        }

        //porównanie 'głębokie'
        return equal(ob1, ob2);
    }
}
