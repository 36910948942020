import BrowserUtil from "./BrowserUtil";

export default class ClassUtil {

    /**
     * http://stackoverflow.com/questions/1249531/how-to-get-a-javascript-objects-class
     *
     * @param obj
     * @returns {*}
     */
    static getClassName(obj) {
        if (String(obj) == "NaN") {
            return "NaN";
        }
        let name = undefined;
        if (obj && obj.constructor && obj.constructor.toString()) {
            //TODO Zrobic jakis polyfill dla IE

            /*
             *  for browsers which have name property in the constructor
             *  of the object,such as chrome
             */
            //AM na IE od jakieśj wersji reacta ma constructor.name ale ustawiony na klasę bazową zamiast faktyczną dlatego trzeba sprawdzać to poniżej
            if(!BrowserUtil.BROWSER_IE && obj.constructor.name) {
                name = obj.constructor.name;
            }
            else {
                var str = obj.constructor.toString();
                /*
                 * executed if the return of object.constructor.toString() is
                 * "[object objectClass]"
                 */

                if(str.charAt(0) == '[')
                {
                    var arr = str.match(/\[\w+\s*(\w+)\]/);
                } else {
                    /*
                     * executed if the return of object.constructor.toString() is
                     * "function objectClass () {}"
                     * for IE Firefox
                     */
                    var arr = str.match(/function\s*(\w+)/);
                }
                if (arr && arr.length == 2) {
                    name = arr[1];
                }
            }
        }

        //JG: Jeśli była podana klasa zamiast referencji do obiektu
        if (name == "Function" && obj.prototype && obj.prototype.constructor) {
            return obj.prototype.constructor.name;
        }
        return name;
    }
}
