/**
 * Created by barte on 25.10.2019.
 */


export default class DateUtil {

    /**
     * BI przeniesione z AS3
     *
     * Metoda porownujaca dwie daty. Zwraca uwage na dzien, miesiac i rok - reszte ignoruje.
     *
     * @param d1 {Date}
     * @param d2 {Date}
     * @return {Boolean} Zwraca true, jesli te dwie daty sa rowne.
     */
    static areEqualDays(d1, d2) {
        if (!d1 || !d2) return false;
        if (d1.getDate() != d2.getDate()) return false;
        if (d1.getMonth() != d2.getMonth()) return false;
        if (d1.getFullYear() != d2.getFullYear()) return false;
        return true;
    }

    /**
     * @return Returns int number of days between two dates.
     * @param from {Date} Starting date of the time period.
     * @param to {Date} Ending date of the time period.
     * @param onlyWorkDays {Boolean}
     * @param includeLastDay {Boolean}
     * @return {int} number of days between two dates.
     * */
    static getTimePeriod(from, to, onlyWorkDays = false, includeLastDay = true) {
        if(!from) return 0;
        if(!to) return 0;
        try {
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            from.setMilliseconds(0);

            to.setHours(0);
            to.setMinutes(0);
            to.setSeconds(0);
            to.setMilliseconds(0);

            if (onlyWorkDays) {
                if (from.getTime() == to.getTime() && !includeLastDay) {
                    return 0;
                }
                let char = 1;
                if (from > to) {
                    /** @type {Date} */
                    let datePom = from;
                    from = to;
                    to = datePom;
                    char = -1;
                }
                let count = 0;
                if (!includeLastDay) {
                    from.setDate(from.getDate() + 1);
                }
                to.setDate(to.getDate() + 1);
                let lastYear = 0;
                while (from < to) {
                    let hol = [];
                    let year = from.getFullYear();
                    if (year != lastYear) {
                        lastYear = year;
                        let easter = DateUtil.getEasterSunday(year);

                        /** @type {Date} */
                        let easterSec = new Date();
                        easterSec.setTime(easter.getTime());
                        easterSec.setDate(easterSec.getDate() + 1);

                        /** @type {Date} */
                        let cc = new Date();
                        cc.setTime(easter.getTime());
                        cc.setDate(cc.getDate() + 60);

                        /** @type {Array} */
                        hol = [new Date(year, 1 - 1, 1), new Date(year, 5 - 1, 1), new Date(year, 5 - 1, 3), new Date(year, 8 - 1, 15), new Date(year, 11 - 1, 1), new Date(year, 11 - 1, 11), new Date(year, 12 - 1, 25), new Date(year, 12 - 1, 26)];
                        hol.push(easter, easterSec, cc); //Wielkanoc i Boże Ciało
                        if (year > 2010) {
                            hol.push(new Date(year, 1 - 1, 6));//3 króli
                        }
                    }
                    let weekDay = from.getDay();
                    let holiday = false;

                    for (let i = 0; i < hol.length; i++) {
                        if (hol[i].getTime() == from.getTime()) {
                            holiday = true;
                            break;
                        }
                    }
                    if (!(weekDay == 0 || weekDay == 6 || holiday)) {
                        count++;
                    }
                    from.setDate(from.getDate() + 1);
                }
                count *= char;
                return count;
            } else {
                return Math.round((to.getTime() - from.getTime()) / DateUtil.DAY_MILLISECONDS) + (includeLastDay ? (to.getTime() >= from.getTime() ? 1 : -1) : 0);
            }
        } catch(e) {
            console.error(e);
        }
        return 0;
    }

    /**
     *
     * @param year {int}
     * @returns {Date}
     */
    static getEasterSunday(year) {
        let a = Math.floor(year / 100);
        let b = a - Math.floor(a / 4);
        let c = year % 19;
        let d = (15 + 19 * c + b - Math.floor((a + 1 - Math.floor((a + 8) / 25)) / 3)) % 30;
        let e = d - Math.floor((c + 11 * d) / 319);
        let s = 22 + e + (140004 - (year + Math.floor(year / 4)) % 7 + b - e) % 7;

        let easter = new Date(year, 2, s, 0, 0, 0, 0);
        return easter;
    }

    /**
     * @returns {Number}
     */
    static get currentTime() {
        return new Date().getTime();
    }

    /**
     * @returns {Date}
     */
    static getDate() {
        return new Date();
    }

    /**
     * Returns date representing previous month before the specified day.
     * @param d Reference date.
     *
     * JG: Skopiowane z AS3
     */
    static getPreviousMonth(d) {
        var n = new Date(d.toString());
        var m = n.getMonth();
        m--;

        if (m < 0) {
            var y = n.getFullYear();
            y--;
            n.setFullYear(y);
        }

        n.setMonth((12 + m) % 12);
        return n;
    }

}

DateUtil.WEEK_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
DateUtil.DAY_MILLISECONDS = 24 * 60 * 60 * 1000;
DateUtil.HOUR_MILLISECONDS = 60 * 60 * 1000;
DateUtil.MINUTE_MILLISECONDS = 60 * 1000;
DateUtil.SECOND_MILLISECONDS = 1000;