import React from 'react';

export default class SiteCartLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.state.numItems = 0;
    }

    componentDidMount() {
        setInterval(()=> {
            this._update();
        }, 1000);

        this._update();
    }

    _update() {
        if (localStorage.getItem("cart")) {
            this.state.cart = JSON.parse(localStorage.getItem("cart"));
            const numItems = this.state.cart.items.length;
            if (numItems !== this.state.numItems) {
                this.setState({ numItems : numItems });
            }
        }
    }

    render() {
        if (!this.state.numItems) {
            return null;
        }
        return <a href="/zamowienie/" title="Twoje zamówienie"><u>Zamówienie <b>{this.state.numItems}</b></u></a>
    }
}
